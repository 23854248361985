<template>
   <div> 
        <b-link v-if="!loading" style="color: #adb5bd; cursor:pointer; outline:none !important;" @click="setRefresh">
            <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
        </b-link>

        <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
    </div>
</template>
<script>

export default {
    name: 'AppRefresh',
    components: {
    },
    
    props: {
            refresh: {
                type: Number,
                default: 0
            },

            loading: {
                type: Boolean,
                default: false
            }
    },
  
    computed: {        
        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        }
    },


    methods: {

        setRefresh() {
              this.cRefresh++;
        },
      
    }

};
</script>

<style>

</style>

