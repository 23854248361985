import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import VueMask from 'v-mask';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueSignature from "vue-signature-pad";
import NotificationsPlugin from './@core/plugins/Notification';
import VueEcho from 'vue-echo-laravel';
import * as VueGoogleMaps from 'vue2-google-maps';
import envVariables from "./util/envVariables";
import Pusher from 'pusher-js';

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
 
// plugin setup
Vue.use(VueMask);
Vue.use(DashboardPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VueSignature);
Vue.use(NotificationsPlugin);

var envVariablesObj = new envVariables();


if(process.env.NODE_ENV != 'production'){
  Pusher.Runtime.createXHR = function () {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return xhr;
  };
  
  Vue.use(VueEcho, {
    broadcaster: 'pusher',
    //authEndpoint : 'http://localhost:8000/broadcasting/auth',
    key: '6BktHgItn90kBs98RC5ndlJMnfU2H0bK',
    cluster: 'mt1',
    encrypted: process.env.NODE_ENV == 'production',
    wsHost: envVariablesObj.getBaseURLforEcho(),
    wsPort: 6001,
    wssPort: 6001,
    enabledTransports: ['ws'],
    forceTLS: process.env.NODE_ENV == 'production',
    channelAuthorization: {
      endpoint : envVariablesObj.getURLforEchoAuthorization(),
      headers: { "CSRF": localStorage.getItem('CSRF') }
    }
  });
}





Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBc2Ho2zmTj2wyrpK0BVpO8ApSbU7pLFPc',
  },
  installComponents: true
});

Vue.prototype.$routeStack = {
  props: {
    
  },
  stack: []
};

Vue.use({
  install: function(Vue, options) {
      Object.defineProperty(Vue.prototype, "uniqId", {
          get: function uniqId() {
              if ('_uid' in this) {
                 return this._uid;
              }
              throw new Error("_uid property does not exist");
          }
      });
  }
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
});
