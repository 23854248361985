<template>
    <div :id="'popover_' + _uid.toString()" class="d-flex flex-wrap custom-component" v-bind:class="{'app-local-search-main':is_focus, 'app-local-search-short-main':!is_focus}" >
        
        <div>
            <b-icon  class="custom-search search-icon" icon="search" @click="clickSearchIcon()" v-if="!is_focus" font-scale="1" ></b-icon> 
        </div>
        <div v-bind:class="{'overflow-div':is_focus, 'app-local-search-short-main':!is_focus}">
        <div  :id="'popoverslot' +  _uid" v-bind:class="{'app-local-search':is_focus, 'app-local-search-short long-search':!is_focus}">
            <b-input-group  class="input-group-merge" :class="{'input-group-merge-filled': search !=  null && search.length > 0 }">
                            
                <b-input-group-prepend class="custom-search-group" @click="clickSearchIcon()" is-text>
                    <b-icon  class="custom-search ml-1 mr-1" icon="search"  v-if="!loading" style="color:#066791" font-scale="1" ></b-icon>      
                    <b-spinner class="align-middle app-custom-input-spinner ml-1 mr-1" style="color:#066791" small size v-if="loading"></b-spinner>  
                </b-input-group-prepend>


                <b-form-input  ref="srchInput" :lazy="true"  @focus="focusSearch()" @blur="blurSearch()"  v-on:keyup="searchTimeOut($event)" :placeholder="trans('search',3)" class="search-input" v-model="search" size="sm" tabindex="0" autocomplete="off" />

                <b-input-group-append @click="clearInput()" v-bind:class="{'custom-x-group':is_focus, 'custom-x-group-unfocus':!is_focus}">
                        <div class="input-group-text custom-group-text" >
                            <b-icon v-if="is_focus" icon="x" class="custom-x" lg   font-scale="1" style="color:#066791"></b-icon>
                            <div v-else class="clear-div"/>
                        </div>
                </b-input-group-append>
            </b-input-group>
        </div>
        </div>
        
        

       
        
        <b-popover  v-if="results != null && popover_show && is_focus && !loading && (!is_change || search.length < 3)" :container="'popoverslot' +  _uid" placement="bottom" triggers="focus" :target="popover_target" class="p-0 m-0"  custom-class="custom-popover-body"    no-fade :show.sync="popover_show"  @hide="popoverHide">
            
            <div class="search-popover" >
                <div v-if="!is_change && results  && Object.keys(results).length > 0 ">
                    <div  v-for="result, name of results" class="p-0 m-0" :key="name">
                          <b-card no-body class="m-2 mt-0" :title="trans('recent-results',3)">
                                <b-card-title class="pr-2 pl-3 pt-2 pb-0 m-0"> {{name}} </b-card-title>
                                <b-card-body class="pl-2 pr-2 pt-1 pb-2">
                                    <b-list-group flush>
                                        <b-list-group-item class="d-flex align-items-center custom-list-group-item" action v-for="item in result" :key="item.id" tabindex="0" @click.prevent="getProfile(item)">
                                            <b-row class="custom-row pl-2 pr-2" align-v="center">
                                                        <b-col  class="align-items-center p-0">
                                                            <b-row class="ml-0 mr-0" align-v="center">
                                                                <b-col cols="12" class="p-0">
                                                                    <span class="app-documents-item app-input-error-ellips pt-0" :key="'m1'+refresh" :ref="'tooltipResults' + item.id + 'main' + _uid"  v-bind="tooltips['tooltipResults' + item.id + 'main' + _uid]">{{item.name}}</span> 
                                                                    <small v-if="item.description != null && item.description.length > 0" :key="'d1'+refresh" v-bind="tooltips['tooltipResults' + item.id + 'description' + _uid]" :ref="'tooltipResults' +  item.id + 'description' +_uid" class="pre-formatted app-input-error-ellips pt-0">{{item.description}}</small>  
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>   
                                            </b-row>
                                        </b-list-group-item> 
                                    </b-list-group>
                                </b-card-body>
                          </b-card>
                    </div>
                </div>


                <div v-else-if="recent_search && search.length < 3" class=" m-0">
                    <b-card class="m-2" no-body>
                        <b-card-title class="pr-2 pl-3 pt-2 m-0">{{trans('recent-search-results',3)}}</b-card-title>
                        <b-card-body class="p-2">
                            <b-list-group flush>
                                <b-list-group-item class="d-flex align-items-center custom-list-group-item p-2" action v-for="item in recent_search" :key="item.id" tabindex="0" @click.prevent="getProfile(item)">
                                    <b-row class="custom-row pl-2 pr-2" align-v="center">
                                                <b-col  class="align-items-center p-0">
                                                    <b-row class="ml-0 mr-0" align-v="center">
                                                        <b-col cols="9" class="p-0">
                                                            <span class="app-documents-item app-input-error-ellips pt-0" :key="'m2'+refresh" :ref="'tooltipRecent' + item.id + 'main' + _uid"  v-bind="tooltips['tooltipRecent' + item.id + 'main' + _uid]">{{item.name}}</span> 
                                                            <small v-if="item.description != null && item.description.length > 0" :key="'d2'+refresh" v-bind="tooltips['tooltipRecent' + item.id + 'description' + _uid]" :ref="'tooltipRecent' +  item.id + 'description' +_uid" class="pre-formatted app-input-error-ellips pt-0">{{ item.description }}</small>  
                                                        </b-col>

                                                            <b-col  class="pr-0 text-muted" cols="3">
                                                            <small>{{item.type}}</small>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>   
                                    </b-row>
                                </b-list-group-item> 
                            </b-list-group>
                        </b-card-body>
                    </b-card>   
                </div>

                <div class="d-flex justify-content-center mt-2 mb-2 align-items-center text-muted" v-else > {{trans('no-results',3)}} </div>
            </div>

        </b-popover>

    </div> 
</template>

<script>

import axios from '@axios';


export default {

    props: ["displayMenu"],

    mounted(){
        this.recent_search =JSON.parse(JSON.parse(localStorage.getItem('user')).recent_search);
    },

    data(){
        return{
            search: '',
            results: [],
            last_search: '',
            is_change: true,
            popover_show: false,
            loading: false,
            timer: null,
            empty_value: null,
            is_focus: false,
            prevent_blur: false,
            tooltips: [],
            recent_search: [],
            refresh: 0,
            popover_target: 'popover_' + this._uid.toString(),
        }
    },

    methods: {
        searchTimeOut(event) {

            this.is_change = true;
            
            this.search = event.target.value;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            if(event.key == 'Enter'){
                this.searchResult();
            }else{
                this.timer = setTimeout(() => {
                    this.searchResult();
                }, 1000);
            }
        },

        blurSearch(){
            var self = this;
            setTimeout(function() {
                if(!self.prevent_blur && !self.popover_show){
                    self.is_focus = false;
                    self.$emit('displayMenu', true);
                }
                self.prevent_blur =  false;
            }, 200);
             
        },

        clickSearchIcon(){
            
            if(this.is_focus){
                this.prevent_blur=  true;
                this.searchResult();
            }else{
                this.focusSearch();
            }
            
            this.$refs.srchInput.$el.focus()
        },

        focusSearch(){
            this.is_focus = true;
            this.$emit('update:displayMenu', false);

            
            var self = this;
            
            setTimeout(function() {
                self.searchResult();
            }, 600);
            
        },

        searchResult() {
            this.popover_show = true;
            this.loading = true;
            if(this.search.length >= 3 || this.isInt(this.search)){

                axios
                    .post("core/quick-search/getList", {
                            text: this.search
                        })
                    .then((res) => {
                            this.results = this.groupBy(res.data, 'type' );
                            this.is_change = false;
                            this.popover_show = true;
                            this.last_search = this.search;
                            this.isOverflowing();
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
            }else{
                this.isOverflowing();
                this.loading = false;
            }
                
        },

        clearInput(){
            if(this.is_focus){
                this.search = '';
                this.popover_show = false;
                this.is_focus = false;
                this.$emit('update:displayMenu', true);
                this.results=[];
            }else{
                this.is_focus = true;
                this.$emit('update:displayMenu', false);
                this.$refs.srchInput.$el.focus();
            }
        },

        popoverHide() {
            var self = this;
            setTimeout(function() {
                if(!self.prevent_blur || !self.is_focus){
                    self.is_focus = false;
                    self.$emit('update:displayMenu', true);
                    self.popover_show = false;
                }
            }, 100);
            
        },

        isOverflowing() {
            this.tooltips = [];
            var self = this;

            setTimeout(function() {
                for(var element in self.$refs){
                    if('tooltip' == element.slice(0, 7)){
                        if(self.$refs[element].length > 0 && self.$refs[element][0].scrollWidth > self.$refs[element][0].offsetWidth){
                            self.tooltips[element] = {'title':self.$refs[element][0].innerHTML}
                        }
                    }
                }
                self.refresh++;
            }, 10);
            
            
        },

        getProfile: function(selectedRow) {
            this.saveRecentSearch(selectedRow);
            this.search =  '';
            this.results = [];
            this.is_focus = false;
            this.$emit('update:displayMenu', true);

            switch(selectedRow.order_number){
                case 0: //Sollicitanten
                case 1: //Flexwerkers
                    if(selectedRow != null && !(this.$router.currentRoute.name == "people-person-profile" 
                        && this.$router.currentRoute.params.id_person_data_main == selectedRow.id)){
                        this.$router.push({ name: "people-person-profile", params: { id_person_data_main: selectedRow.id } });
                    }
                    break;
                case 2: //Prospects
                case 3: //Klanten
                    if(selectedRow != null && !(this.$router.currentRoute.name == "clients-profile-organization" 
                        && this.$router.currentRoute.params.id_client_data_main == selectedRow.id)){
                        this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: selectedRow.id } });
                    }
                    break;
                case 4: //Locations
                    if(selectedRow != null && !(this.$router.currentRoute.name == "clients-profile-location" 
                        && this.$router.currentRoute.params.id_location_data_main == selectedRow.id)){
                        this.$router.push({ name: "clients-profile-location", params: { id_location_data_main: selectedRow.id } });
                    }
                    break;
                case 5: //Department
                    if(selectedRow != null && !(this.$router.currentRoute.name == "clients-profile-department" 
                        && this.$router.currentRoute.params.id_department_data_main == selectedRow.id)){
                        this.$router.push({ name: "clients-profile-department", params: { id_department_data_main: selectedRow.id } });
                    }
                    break;
                case 6: //Users
                    if(selectedRow != null && !(this.$router.currentRoute.name == "users-profile" 
                        && this.$router.currentRoute.params.id_user == selectedRow.id)){
                        this.$router.push({ name: "users-profile", params: { id_user: selectedRow.id } });
                    }
                    break;
            }
            this.popover_show = false;
        },
        
        saveRecentSearch(item){
            axios
                    .post("core/quick-search/addRecentSearch", {
                            item: JSON.stringify(item),
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
            var userItem = JSON.parse(localStorage.getItem('user'));
            var recent_items = JSON.parse(userItem.recent_search);

            if(recent_items == null){
                recent_items = [];
            }
            

            recent_items.forEach(function(recent_item, index){
                if(recent_item.id == item.id && recent_item.type == item.type){
                    recent_items.splice(index, 1);
                }
            });

            if(recent_items.length == 5){
                recent_items.pop();
            }

            recent_items.unshift(item);

            this.recent_search = recent_items;
            userItem.recent_search =  JSON.stringify(recent_items);
            localStorage.setItem('user', JSON.stringify(userItem));
        },


        groupBy(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },

        isInt(value) {
            return !isNaN(value) && 
            parseInt(Number(value)) == value && 
            !isNaN(parseInt(value, 10));
        }


    }
};

</script>

<style scoped>


    .input-group-merge:hover .search-input{
        border: solid 1px #0D7BB3;
        border-right: none;
        border-left: none;
    }

    .input-group-merge-filled .search-input{
        border: solid 1px #0D7BB3;
        border-right: none;
        border-left: none;
    }

    .search-input{
        height: 32px;
        transition: none;
    }

    .custom-search-group:focus{
        border-radius: 0.25rem;
    }
    .input-group-merge:hover .input-group-text{
        border-radius: 0.25rem, 0.25rem, 0,0.25;
        border: solid 1px #0D7BB3;
        border-right: none;
    }

    .input-group-merge-filled .input-group-text{
        border-radius: 0.25rem, 0.25rem, 0,0.25;
        border: solid 1px #0D7BB3; 
        border-right: none;
    }

    .input-group-merge:hover .custom-group-text{
        border-radius: 0.25rem , 0.25rem , 0,25rem ,0;
        border: solid 1px #0D7BB3;
        border-left: none;
    }

    .input-group-merge-filled .custom-group-text{
        border-radius: 0.25rem , 0.25rem , 0,25rem ,0;
        border: solid 1px #0D7BB3; 
       border-left: none;
    }

    .input-group-merge .input-group-text{
        transition: none !important;
    }

    .input-group-merge .custom-group-text{
        transition: none !important;
    }


    .search-input::placeholder {
        color: #174066;
    }


    .custom-search-group:focus-within{
        border-radius: 0.25rem;
    }
    .input-group-merge:focus-within .input-group-text{
        border-radius: 0.25rem, 0.25rem, 0,0.25;
        border: solid 1px #0D7BB3;
        border-right: none;
    }

    .input-group-merge:focus-within .custom-group-text{
        border-radius: 0.25rem , 0.25rem , 0,25rem ,0;
        border: solid 1px #0D7BB3;
        border-left: none;
    }

    .input-group-merge:focus-within .search-input{
        border: solid 1px #0D7BB3;
        border-right: none;
        border-left: none;
    }

    .popover{
        margin-top: 2px;
        width: 350px !important;
        transform: translate3d(0,54px,0) !important; 
        margin-left: 16px !important;
        max-width: none !important;
    }

    .input-group-merge:hover{
         box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important; 
    }

     .input-group-merge{
        transition: none !important;
    }

    .input-group-merge:focus-within{
         box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important; 
    }

    .app-local-search-short-main{
        width: 350px;
    }

    .app-local-search{
        width: 350px;
        transition: width 500ms;
    }

    .app-local-search-short{
        width: 200px;
        transition: width 500ms;
    }

    .app-local-search-main{
            width: 350px;
    }

    .search-icon{
        display: none;
    }

    .long-search{
        display: inherit;
    }

    .popover-body{
        width: 100% !important;
    }

    .search-popover{
        width: 100% !important;
    }

    .custom-row{
        width: 100% !important;
    }

    .input-group-text{
        padding: 7px !important
    }

    @media (max-width: 1000px){

        .app-local-search-short{
            width:200px;
            transition: width 500ms;
        }

       .app-local-search-short-main{
            width: 200px;
        }

        
        .app-local-search-main{
            width: 200px;
            
        }

        .overflow-div{
            min-width: 300px;
            overflow: visible;
        }

        .app-local-search{
            min-width: 80px;
            width: 300px;
            transition: width 500ms;
        }

        .popover{
            width: 300px !important;
        }

    }
    


     @media (max-width: 780px){

        .app-local-search-short-main{
            width: 300px;
        }

        .app-local-search-main{
            width: 300px;
            
        }

        .overflow-div{
            min-width: none;
            overflow: visible;
        }
    }


    @media (max-width: 660px){
        
        .overflow-div{
            width: 100%
        }

        .app-local-search{
            width: 100% !important;
            transition: width 500ms;
        }

        .app-local-search-short-main{
            width: 200px;
        }

        .app-local-search-main{
            width: 100% !important;
        }

        .popover{
             width: calc(100% -  32px) !important;
        }
        
    }



    @media (max-width: 560px){

        .long-search{
            display: none;
        }
        
        .custom-component{
            min-width: 30px;
            padding-left: 16px;
            width: calc(100% - 16px) !important;
            transition: width 500ms;
        }

        .app-local-search-short-main{
            width:20px;
            margin-right: 4px;
        }

        

        .search-icon{
            display: inherit;
            cursor: pointer;
        }

    }

    .custom-x{
        height: 20px;
        width: 20px;
       color:#066791;
    }


    .custom-group-text{
        padding: 4px !important;
    }
    
    .search-popover {
        max-height: calc(100vh - 80px) ;
        overflow-y: auto;
    }

    .custom-popover-body >>> .popover-body {
        padding: 0;
    }

    .custom-row{
        margin-left: 0px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .custom-list-group-item{
        cursor:pointer; 
        padding: 0px !important; 
        outline: none !important;
    }

    .app-custom-input-spinner{
        height: 14px;
        width: 14px;
    } 

    .custom-search-group:hover .custom-search{
        color: #066791 !important;
        cursor: pointer;
    }

    .custom-search-group:hover{
        cursor: pointer;
    }

    .custom-x-group:hover{
        cursor: pointer;
    }

    .custom-x-group:hover .custom-x{
        color: #066791 !important;
    }

    .custom-x-group-unfocus:hover{
        cursor: text;
    }

    .pre-formatted {
        white-space: pre;
    }
    
</style>

<style>
   
</style>