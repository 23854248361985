<template>
    <div  v-bind:class="{'ml-2' : idParent != null}" class="app-tree-list">
        <div v-for="element in cElements" v-bind:key="element.id">
            <div :class="{'app-main-div' : !disabled, 'app-main-div-active': element.active}"  class="d-flex align-items-center justify-content-between  w-100 p-2" @mouseover="mousOver(element)">
                <div>
                <b-icon v-if="element.open == false && element.children > 0" :class="{'app-local-parent-mouse' : !disabled, 'app-local-active' : element.active, 'custom-color' : !element.active}" @click="!disabled ? element.open = true: ''" icon="caret-right-fill" style="width: 12px; height: 12px; margin-right:4px;"></b-icon>
                <b-icon v-else-if="element.open == true && element.children > 0" :class="{'app-local-parent-mouse' : !disabled, 'app-local-active' : element.active, 'custom-color' : !element.active}" @click="!disabled ? element.open = false: ''" icon="caret-down-fill" style="width: 12px; height: 12px; margin-right:4px;"></b-icon>
                <b-icon v-else-if="element.children == 0" icon="caret-right" :class="{'app-local-active' : element.active, 'custom-color' : !element.active}" style="width: 12px; height: 12px; margin-right:4px;"></b-icon>
                <div :class="{ 'app-local-active': element.active && !disabled, 'app-local-disbled custom-color' : (disabled && !element.active), 'app-local-disbled  app-local-disbled-active' : (disabled && element.active), 'custom-color' : (!disabled && !element.active) }" class="app-local-parent-mouse d-inline" @click="!disabled ? onClick(element): ''"> {{element.name}} <span v-if="element.children > 0"> ({{ element.children }})</span> </div>
                </div>
                <div>
                    <div v-if="!disabled"   @click="onClickIcon(element)" class="app-icon-slot">
                        <slot name="custom-icons" /> 
                    </div> 

                    <template v-if="element.active && isDropdownMenu  && !disabled">
                        <b-dropdown class="float-right custom-size" toggle-class='pt-0' variant="link" no-caret right boundary="window">
                            <template class="text-right" #button-content>
                                <b-icon icon="three-dots-vertical" class="text-body custom-size"></b-icon>
                            </template>
                            
                            <slot name="menu-items" v-bind:data="returnActiveItem()" />                                                                
                        </b-dropdown>
                    </template>
                </div>

               
            </div>
            <app-tree-list v-if="element.children > 0 && element.open" @mouseover="mousOver" @actualSelect="onClickIcon" :level="level+1" @click="onClick" :disabled="disabled" :elements="elements" :idParent="element.id"> 
                <template slot="menu-items">
                    <slot name="menu-items" v-bind:data="returnActiveItem()"/>
                </template>

                <template slot="custom-icons">
                    <slot name="custom-icons"/>
                </template>
            </app-tree-list>
        </div>    
    </div>

</template>
<script>


import axios from "@axios";
import AppTreeList from "@core/components/AppTreeList.vue";
export default {

    name: "AppTreeList",
    components: {
        AppTreeList,
    },

    props: {
            elements: {},
            idParent: {default: null},
            disabled:  {default: false},
            isDropdownMenu: {default: false},
            level: {default: 0} ,
            active_item: {default: 0}
    },

    created() {
      
    },

    computed: {
        
        cElements: {
            get() { return this.elements.filter((element) => element.id_parent == this.idParent) }
        },
    },

    data() {
        return {       
        };
    },

    methods: {
        onClick(element) { 
            this.$emit('click', element);
        },


        mousOver(item){
            this.$emit('mouseover', item);
        },

        onClickIcon(element) { 
            this.$emit('actualSelect', element);
        },



        returnActiveItem(){
            
            return this.elements.find(value => value.active == true);
        },

        
    }

};
</script>

<style>
.app-tree-list{
    font-size: 14px;
}

.app-local-parent-mouse {
    cursor: pointer;
}

.app-local-disbled {
    cursor: default;
}

.custom-size {
    height: 18px;
}

.app-main-div:hover{
    background-color: #f6f9fc;
}

.app-main-div-active{
    background-color: #f6f9fc;
}

.app-local-active  {
     color: #174066 !important;
     font-weight: 500;
}

.app-icon-slot{
    display: none;
}

.custom-color{
     color: rgba(0, 0, 0, 0.5)
}

.app-main-div:hover .app-icon-slot{
    display: block;
}
</style>