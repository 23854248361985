import Vue from 'vue'

// axios
import axios from 'axios'
import router from '../routes/router'
import envVariables from "../util/envVariables";

const getRefreshToken = async () => {
  refresh_in_progress = true;
  await axiosAuthIns.post('/authorization/oauth/token', {
    grant_type: 'refresh_token',
    client_id: 34,
    scope: 'app-main'
}).then((res)=>{
    let currentTime = new Date().getTime() / 1000; 
    
    access_token_expiries_time = currentTime + res.data.expires_in;
    localStorage.setItem('access_token_expiries_time', access_token_expiries_time);
    refresh_token_expiries_time = currentTime + res.data.refresh_expires_in;
    localStorage.setItem('refresh_token_expiries_time', refresh_token_expiries_time);

  }).catch(() => {
      localStorage.removeItem('user');
      router.push({ name: 'Login' , query: { redirect: window.location.hash.substr(1) } });
      refresh_result = false;
  }).finally(()=>{
    refresh_in_progress = false;
   
  });
}


function checkRefreshInProgress() {
  const poll = resolve => {
    if(!refresh_in_progress) resolve();
    else setTimeout(_ => poll(resolve), 10);
  }

  return new Promise(poll);
}

let access_token_expiries_time = null;
let refresh_token_expiries_time = null;
let refresh_in_progress = false;
let refresh_result = true;


var envVariablesObj = new envVariables();



const axiosAuthIns = axios.create({
  withCredentials: true,
  crossDomain: true,
  xhrFields: {
    withCredentials: true
  },
  baseURL: envVariablesObj.getBaseURLforAxios(),
  headers: {
    Accept: 'application/json',
  }
})


const axiosIns = axios.create({
  withCredentials: true,
  crossDomain: true,
  xhrFields: {
    withCredentials: true
  },
  baseURL: envVariablesObj.getBaseURLforAxios(),
  headers: {
    Accept: 'application/json',
  //  'Sec-Fetch-Site': 'same-origin'
  }
})

axiosIns.interceptors.request.use(async function (config) {
  config.headers.CSRF =  localStorage.getItem('CSRF');
  await checkRefreshInProgress();

  return config;
});

axiosIns.interceptors.response.use(function (response) {

    if(response.config.url != 'issue/getMyIssues'){
        if(response.headers.csrf != null){
            localStorage.setItem('CSRF', response.headers.csrf);
        }
        if(response.config.url == 'authorization/oauth/token'){
          let currentTime = new Date().getTime() / 1000; 

          refresh_result = true;
          
          access_token_expiries_time = currentTime + response.data.expires_in;
          localStorage.setItem('access_token_expiries_time', access_token_expiries_time);
          refresh_token_expiries_time = currentTime + response.data.refresh_expires_in;
          localStorage.setItem('refresh_token_expiries_time', refresh_token_expiries_time);
        }else if(access_token_expiries_time == null){
          access_token_expiries_time = parseFloat(localStorage.getItem('access_token_expiries_time'));
        }
        
        let currentTime = parseFloat(new Date().getTime() / 1000); 
        if(access_token_expiries_time - currentTime < 600 && !refresh_in_progress){ // less than 10 minutes
            getRefreshToken();
        }

        localStorage.setItem('pause', 0);
    }

    return response
},async function (error) {
  //Obsługa błędów
  if (error.response) {
    if (error.response.status === 404) {

      router.push({ name: "404-error-page" });

    }else if(error.response.status === 500){
      var notification = '';
      if('description' in error.response.data) notification = error.response.data.description;
      else notification = error.response.data.message
      Vue.prototype.$notify(notification);
      //router.push({name: "500-error-page"});

    }else if(error.response.status === 452){//File not found - nasz customy status code pula od 452 - 499 jest pusta

      
    }

    if ((error.response.status === 401)) {
      if(error.response.config.url == 'issue/getMyIssues'){
        localStorage.setItem('pause', 1);
      }else{
        await checkRefreshInProgress();
        let currentTime = new Date().getTime() / 1000; 
        if(access_token_expiries_time - currentTime < 0 || error.config.repeat === true){
          await getRefreshToken();
        }
        if(refresh_result){
            error.config.repeat = true;
            return axiosIns.request(error.config).then();
         
        }
      }
      

  }else if(error.response.status === 403){
    //router.push({name: "403-error-page"});
    if(error.response.data.error != 'token_missing'){
      router.push({name: "403-error-page"});
    } else {
      router.push({ name: 'Login' , query: { redirect: window.location.hash.substr(1) } })
    }
    //localStorage.removeItem('user');
  }else{
    console.log('error');
    return Promise.reject(error)
  }
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns
export default axiosIns
