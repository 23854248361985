<template>
    <div  v-bind:class="{'app-local-checkbox': minHeight, 'd-flex align-items-center': minHeight}">
        <validation-provider v-slot="{ errors}" ref="provider" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <div :class="{'custom-checkbox-error': errors.length > 0 && errors[0] != ''}">
            <b-form-checkbox :id="id"  v-model="cModel"  class="custom-control-primary align-middle" :indeterminate.sync="cIndeterminate" :disabled="disabled" value="1" unchecked-value="0" size="lg" @change="changeValue">
                <slot name='custom-right-label'></slot>                              
                <slot >{{rightLabel}}</slot>      
            </b-form-checkbox>
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error">{{ errors[0] }}</small> 
            </div>        
        </validation-provider>
    </div>
</template>

<script>

export default {
    name: "AppCheckBox",

    props:{
        id:{ type: String },
        value: {},
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        rightLabel: {type: String, default: ""},
        disabled: {type: Boolean, default: false},
        loading: {type: Boolean, default: true},
        minHeight: {type: Boolean, default: false},
        indeterminate: {type:Boolean, default:false}
    },

    computed:{
        cModel:{
            get() { return this.value },
            set(value) { this.$emit('input', value) }
        },

        cIndeterminate:{
            get() { return this.indeterminate },
            set(value) { this.$emit('update:indeterminate', value) }
        }
    },


    methods:{
        changeValue(event) {
            this.$emit('change', event);
        },
    }

}
</script>
<style scoped>
.app-local-checkbox{
    min-height: 34px;
}
</style>