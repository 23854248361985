<template>
  <div class="wrapper">
  
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{ name: trans('dashboard', 5), icon: 'ni ni-chart-pie-35', path: '/dashboard'}" />
        <sidebar-item :link="{ name: trans('actions', 5), icon: 'ni ni-bullet-list-67', path: '/issues'}" />
        
        <hr class="my-3 app-local-line">

        <sidebar-item :link="{ name: trans('applicants' , 5), icon: 'ni ni-single-copy-04', path: '/people/applicants/list'}" />
        <sidebar-item :link="{ name: trans('employee', 5), icon: 'ni ni-single-02', path: '/people/employees/list'}" />
        <sidebar-item v-if="first_release == 0" :link="{ name: trans('cp-planner',190), icon: 'ni ni-ungroup', path: '/planning/planner'}" />
      
        <hr class="my-3 app-local-line">

        <sidebar-item :link="{ name: trans('suspect', 5), icon: 'ni ni-cloud-download-95', path: '/clients/suspects'}" />
        <sidebar-item :link="{ name: trans('prospect', 5), icon: 'ni ni-zoom-split-in', path: '/clients/prospects'}" />
        <sidebar-item :link="{ name: trans('client', 5), icon: 'ni ni-building', path: '/clients/list'}" />
      
        <hr class="my-3 app-local-line" v-if="first_release == 0">
        
        <sidebar-item v-if="first_release == 0" :link="{ name: trans('offers',173), icon: 'ni ni-calendar-grid-58', path: '/planning/offers'}" />
        <sidebar-item v-if="first_release == 0" :link="{ name: trans('registraion', 5), icon: 'ni ni-archive-2', path: '/planning/registrations'}" />
        <sidebar-item v-if="first_release == 0" :link="{ name: trans('notification-queue', 5), icon: 'ni ni-email-83', path: '/planning/queue'}" />
        <sidebar-item v-if="first_release == 0" :link="{ name: trans('placment', 5), icon: 'ni ni-app', path: '/planning/placements'}" />
        <sidebar-item v-if="first_release == 0" :link="{ name: trans('declaration-services',5), icon: 'ni ni-time-alarm', path: '/planning/hours'}" />
       
        <hr class="my-3 app-local-line" v-if="userRole == 1">
        <sidebar-item v-if="userRole == 1" :link="{ name: trans('setting', 5), icon: 'ni ni-settings-gear-65', path: '/settings/settings'}" />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <app-navigator :currentRoute="$route" />

      <div @click="$sidebar.displaySidebar(false)" style="min-height: calc(100vh - 208px); padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },

    created(){
      this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role
    },

    data(){
      return{
        userRole: null,
        first_release: JSON.parse(localStorage.getItem('user')).first_release,
        icons: {
                people: require('../../assets/images/svg/menu_people.svg')
        }
      }
    },

    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
.app-local-line {
    min-width: 80%;
  }
</style>
