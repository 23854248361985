<template>
    <b-row>
        <b-col cols="auto" v-bind:class="{'pl-0': cListType == 3}">
            <b-input-group v-if="cListType != 3" class="app-local-input-group" >
                <b-input-group-prepend v-if="changeArrows" class="app-local-prepend" :class="{'app-local-prepend-filled' : (cListType == 1 && cChoosedDate != null) || (cListType == 2 && cWeekDates != null)}">
                    <b-button variant="transparent" @click="previous" class="app-input-button-group app-icon-button app-local-left-button" :class="{'app-input-button-group-filled': (cListType == 1 && cChoosedDate != null) || (cListType == 2 && cWeekDates != null)}"><b-icon class="custom-icon-color" icon="arrow-left"></b-icon></b-button>
                    <b-button variant="transparent" @click="next" class="app-input-button-group app-icon-button app-local-left-button" style="border-left: none;" :class="{'app-input-button-group-filled': (cListType == 1 && cChoosedDate != null) || (cListType == 2 && cWeekDates != null)}"><b-icon class="custom-icon-color" icon="arrow-right"></b-icon></b-button>
                </b-input-group-prepend>
              <app-date v-model="cChoosedDate" v-if="cListType == 1" v-bind:class="{'app-local-input': changeArrows}" style="width:140px;" :clearable="clearable" />
              <app-week v-model="cWeekDates" v-if="cListType == 2" v-bind:class="{'app-local-input': changeArrows}" style="width:140px;" :clearable="clearable"/>
            </b-input-group>                                  
        </b-col>
        <b-col class='pl-0' cols="auto" v-if="onlyWeek == false">
            <b-button-group  size="sm">
                <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cListType !== 1, 'app-list-button-selected':  cListType === 1}" @click="cListType = 1">{{trans('day-part-day',182)}}</b-btn>
                <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cListType !== 2, 'app-list-button-selected':  cListType === 2 }" @click="cListType = 2">{{trans('week',3)}}</b-btn>
                <!--<b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cListType !== 3, 'app-list-button-selected':  cListType === 3 }" @click="cListType = 3">Alle</b-btn>-->
            </b-button-group>
        </b-col>
    </b-row>
       
</template>
<script>
import moment from "moment";

export default {
    name: 'AppDateWeekSelector',

    props: {
       choosedDate: {},
       listType:{type: Number, default: 1},
       weekDates:{type: String, default: ""},
       onlyWeek: {type: Boolean, default: false},
       changeArrows: {type: Boolean, default: false},
       clearable: {type: Boolean, default: false}
    },

    computed: {
        cChoosedDate:{
            get() {return this.choosedDate },
            set(value) {    
                if(value == 'Invalid date'){
                    this.$emit('update:choosedDate', null) 
                }else{
                    this.$emit('update:choosedDate', value) 
                }}
        },

        cListType:{
            get() {return this.listType },
            set(value) { this.$emit('update:listType', value) }
        },

        cWeekDates:{
            get() {return this.weekDates },
            set(value) { 
                if(value == 'Invalid date'){
                    this.$emit('update:weekDates', null) 
                }else{
                    this.$emit('update:weekDates', value) 
                }}
        }
       
    },
    

    data() {
        return { 
        };
    },

    methods:{
        next() {
            if(this.cListType == 1 && this.choosedDate != null){
                var date = moment(this.cChoosedDate, 'DD-MM-YYYY').toDate();
                date = moment(date).add(1, 'd');
                this.cChoosedDate = moment(date).format('DD-MM-YYYY');

            } else if(this.cListType == 2 && this.weekDates != null){
                var date = moment(this.cWeekDates, 'YYYY-W').toDate();
                date = moment(date).add(1, 'w');
                this.cWeekDates = moment(date).format('YYYY-W');
            }
        },
        previous(){
            if(this.cListType == 1 && this.choosedDate != null){
                var date = moment(this.cChoosedDate, 'DD-MM-YYYY').toDate();
                date = moment(date).subtract(1, 'd');
                this.cChoosedDate = moment(date).format('DD-MM-YYYY');

            } else if(this.cListType == 2 && this.weekDates != null){
                var date = moment(this.cWeekDates, 'YYYY-W').toDate();
                date = moment(date).subtract(1, 'w');
                this.cWeekDates = moment(date).format('YYYY-W');
            }
        }
    }

   
};
</script>
<style>

.app-local-input .app-datepicker{
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left: none !important;
}

.app-local-input .app-datepicker:hover{
    box-shadow: none !important;
    
}

.app-local-input-group{
    transition: none !important;
}

.app-local-input-group:hover{
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important;
}

.app-local-input-group .app-input-button-group{
    transition: none !important;
}

.app-local-input-group:hover .app-input-button-group{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-local-input-group:hover .app-datepicker{
    border-color: #066791 !important;
}

.app-local-input-group:focus-within .app-input-button-group{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;
}

.app-input-button-group-filled{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-local-prepend{
    border: 1px solid #D0D5DD;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.app-local-input-group:hover .app-local-prepend, .app-local-input-group:focus-within .app-local-prepend{
    border-color: #066791;
    border-right-color: #D0D5DD !important;
}

.app-local-prepend-filled{
    border-color: #066791;
    border-right-color: #D0D5DD !important;
}

.app-local-left-button{
    border-right: 1px solid #D0D5DD !important;
}

.custom-icon-color{
    color: #066791
}

</style>

