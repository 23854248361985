<template>
    <div > 
        <b-link :id="'popover' +  _uid" class="ml-1" style="color: #98A2B3 ; cursor:pointer;outline: none !important;" @click="openPopover">
            <b-icon :icon="filteringObject.values.length > 0 ? 'funnel-fill' : 'funnel'" />
        </b-link>
        <b-popover :id="'popover_body' +  _uid" v-if="show_popover" :show.sync="show_popover" :key="refresh" :target="'popover' + _uid" ref="popover" triggers="focus"  no-fade placement="bottomleft"  @hide="closePopover" @show="openPopover" boundary="window">
            <template #title>
                {{label}}
            </template>
           
           <div ref="content">
                               
                    <b-card no-body class="mb-2">
                        <b-card-body id="date_focus">
                            <ValidationObserver ref="form">
                                <app-select v-model="type" :label="trans('type',182)" :options="types" :clearable="false" class="mt-1"/>
                                <app-date v-model="date_from" :label="trans('date-start',3)"  class="mt-1" @open="calendar_open = true" @closed="closeCalandar" :validatorName="trans('date-start',3)" :validatorRules="type.value == 4 ? 'required|date-check' : 'date-check'"/>
                                <app-date v-model="date_to" :label="trans('date-end',3)" v-if="type == 4 || type.value == 4" :disabledDatesPass="cDisableDate" @open="calendar_open = true" @closed="closeCalandar" :validatorName="trans('date-end',3)" class="mt-1" :validatorRules="type.value == 4 ? 'required|date-check' : 'date-check'"/>
                                <app-input type="hidden" ref="inp" />
                            </ValidationObserver>
                        </b-card-body>
                    </b-card> 
                    <b-row class="align-items-center">
                        <b-col cols="auto" class="mr-auto">
                            <b-link class="ml-1" style="color: #98A2B3; cursor:pointer;" @click="clearFilter" v-if="date_from != null || date_to != null">{{trans('remove',175)}} </b-link>
                        </b-col>
                        <b-col cols="auto">
                            <app-button type="primary" ref="setFilters" @click="setFilter()" >{{trans('apply',175)}}</app-button>
                        </b-col>
                    </b-row>
            </div>
            
           </b-popover>
           
    </div>  
</template>
<script>

import axios from "@axios";
import { throws } from "assert";
import moment from "moment";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["label", "filteringObject", "fieldName"],

    computed: {    
       cFilteringObject: {
        get() { return this.filteringObject },
        set(value) { this.$emit('update:filteringObject', value) }
       },
       cDisableDate: {
        get(){
            var today = moment(moment.now());
            var d = today.diff(moment(this.date_from, 'DD-MM-YYYY'), 'days');
            return d;
        }
       }
    },

    created(){
        if(this.filteringObject.parameters.type == null){
            this.type = { value: 1, name: this.trans('equal',175) },
            this.date_from = null    
            this.date_to = null
        } 
    },

    watch: {
        "filteringObject": {
              handler: function(val) {
                if(this.filteringObject.parameters.type){
                    this.type = this.filteringObject.parameters.type;
                    this.date_from = this.filteringObject.values[0]     
                    this.date_to = this.filteringObject.values[1]      
                }          
              },
            deep: true,
          },
    },

    data() {
        return {
            loading: false,
            search: "",
            date_from: null,
            date_to: null,
            type: 1,
            types: [
                { value: 1, name: this.trans('equal',175) },
                { value: 2, name: this.trans('greater-than',175) },
                { value: 3, name: this.trans('lower-than',175) },
                { value: 4, name: this.trans('between',175) }
            ],
            items: this.filteringObject.customList ? this.filteringObject.customList:[],
            checkedCount:0,
            type_change: false,
            date_change: false,
            show_popover: false,
            date_popover: false,
            refresh: 0,
            calendar_open: false,
            first_click: true,
            first_click_outside: true,
            check_result:null
        };
    },
    
    methods: {
    
        async openPopover() {
            this.show_popover = true;

            if(this.first_click){
                this.first_click_outside = true;
                window.addEventListener('click', this.modalClick);

            }

        },

        modalClick(e) {
            if (document.getElementById('popover_body' +  this._uid) && document.getElementById('popover_body' +  this._uid).contains(e.target)){
                    // Clicked in box
                } else{
                    if(!this.first_click_outside){
                        if(!this.calendar_open){
                            this.show_popover = false;
                            this.first_click = true;
                            window.removeEventListener('click',this.modalClick);
                            
                        }

                        
                    }
                    this.first_click_outside = false;
                }
            
        },

        closePopover(event) {
           
            event.preventDefault();
            
        },

        clearFilter() {
            var nFilteringObject = this.filteringObject;

            this.date_from = null;
            this.date_to = null;  
            this.type = { value: 1, name: this.trans('equal',175) },     

            this.checkChanged();

            nFilteringObject.values = [];
            this.checkedCount = 0;
            this.$refs.popover.$emit('close');
        },

        setFilter() {

            if(this.date_from == 'Invalid date' || this.date_to == 'Invalid date' || (this.date_from == null && this.date_to == null)){
                this.$refs.popover.$emit('close');
                window.removeEventListener('click',this.modalClick);
                this.show_popover = false;
                return;
            }

            if(this.type.value == 4){
                this.$refs.form.validate().then((result) => {
                    if (!result) {   
                        return false;
                    }
                    this.checkChanged();
                })
            }else{
                this.checkChanged();
            }
            
        },

        async closeCalandar(){
            setTimeout(() => {
                           this.calendar_open =false;
                        
            }, 50);
        },

        checkChanged(){
            if(JSON.stringify(this.cFilteringObject.values) !== JSON.stringify([this.date_from, this.date_to])){
                this.cFilteringObject.changed++;
            }

            if(this.cFilteringObject.parameters.type != this.type && this.date_from != null){
                this.cFilteringObject.changed++;
            }

            this.cFilteringObject.parameters.type = this.type;
            this.cFilteringObject.values.splice(0, 0,this.date_from);
            this.cFilteringObject.values.splice(1, 0, this.date_to);

            this.show_popover = false;
            this.first_click = true;
            this.checkedCount++;

            window.removeEventListener('click',this.modalClick);
            this.$refs.popover.$emit('close');
        },

    },

};
</script>

<style scoped>
.app-spinner-size {
    max-width:20px;
    max-height:20px;
}

.popover .arrow {
    display: none !important;
}

.custom-control:focus {
    outline: none !important;
}

.input-group-text {
    border: none;
}

.card-body {
    padding: 4px;
}
.input-group-text .b-icon{
    overflow: visible;
}


.container {
             width: 100%;
             height: 100%;
             background-color: green;
             display: flex;
             justify-content: center;
             align-items: center;
         }
         .child-div {
             height: 100%;
             background-color: red;
             margin: 0 20px;
         }
</style>