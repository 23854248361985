<template>
    <b-row>
            <b-col cols="12">
                <hr v-if="isLine" class="app-local-line" v-bind:class="{'app-local-line-margin' : text.length > 0}" />                    
                <h4 v-if="text.length > 0">{{text}}</h4>
            </b-col>
        </b-row> 
</template>

<script>

export default {
    name: "AppDividerModal",
    props: {
            isLine: { type: Boolean, default: false },
            text: { type: String, default: ""}
    },

};
</script>

<style scoped>
.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important; 
    margin-bottom:5px !important; 
    margin-top:5px !important  
}

.app-local-line-margin {
    margin-bottom:10px !important; 
}
</style>