<template>
    <div>
    <b-badge  :variant="setVariant"  pill> 
        <slot> 
        </slot>
    </b-badge>
    </div>
</template>
<script>
export default {
    name: "AppBadge",
    props: {


        type: {
            type: String,
            default: "default",
        },

    },

    computed: {
        setVariant:function(){
            if(this.type == 'neutral'){
                return 'neutral';
            }else if(this.type == 'succes'){
                return 'succes';
            }else if(this.type == 'pending'){
                return 'pending';
            }else if(this.type == 'warning'){
                return 'warning';
            }else if(this.type == 'attention'){
                return 'attention';
            }else{
                return 'neutral';
            }
        }
    },


};
</script>

