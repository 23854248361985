<template>
    <div>
        <label v-if="label" class="app-input-top-label">{{label}}</label>
        <validation-provider v-slot="{ errors}" ref="provider" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <b-form-input 
                id="appSlider"
                v-model="val" 
                type="range"
                :min="min"
                :max="max"
                :step="step"
                :key="refresh"
                class="app-slider"
                
                @input="input"
                @change="change"
                
            />
            <small v-if="errors.length > 0 && errors[0] != '' && !disabled" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>
        </validation-provider>
        <div class="d-flex app-slider-mark-label justify-content-between" v-if="markLabel || showValue">
            <span class="app-slider-left-mark" v-if="markLabel">{{min}}</span>
            <span style="position:absolute; margin-left: 15px" v-if="showValue">
                <span id="myValue" class="app-local-value">{{ val }}</span>
            </span>
            <span class="app-slider-right-mark" v-if="markLabel">{{max}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "AppSlider",

    props: {
        id: { type: String },
        value: { type: Number },
        type: { type: String, default: "text"},
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        min: {type: String},
        max: {type: String},
        step: {type: String},
        markLabel: {type: Boolean, default: false},
        showValue: {type: Boolean, default: false}
    },

    computed: {
        cValue: {
            get() { return this.value },
            set(value) { this.$emit('input', value) }
        }
    },
    
    created(){
        if(this.cValue != null)
        this.val = this.cValue.toString();
        window.addEventListener("resize", this.myEventHandler);
    },

    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },

    watch: {
        val: {
            handler: function(val) {
                if(this.showValue){
                    this.getValuePosition()
                }               
            },
        },
    },

    data(){
        return {
            val: null,
            refresh: 1,
        }
    },
    methods: {

        input(event){
            this.cValue = parseInt(event);
        },

        getValuePosition(){
            if(this.showValue){
                setTimeout(() => {
                    var myRange = document.querySelector('#appSlider');
                    var myValue = document.querySelector('#myValue');

                    var off = myRange.offsetWidth / (parseInt(myRange.max) - parseInt(myRange.min));
                    //todo
                    if(this.val == this.min || this.val == this.max){
                        var px =  ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.offsetParent.offsetWidth / 2);
                        if(this.val == this.min){
                            px = -1;
                        }else{
                            if(this.val >= 100 && this.val < 1000){
                                px = px-1;
                            }else if(this.val >= 10 && this.val < 100){
                                px = px+1;
                            }else if(this.val >= 1000 && this.val < 10000){
                                px = px-7;
                            }else if(this.val >= 10000 && this.val < 100000){
                                px = px-11;
                            }else if(this.val >= 100000){
                                px = px-15;
                            }
                        }
                    }else{
                        var percent = (this.val - this.min) / (this.max - this.min);
                        var px = 0;
                    if(this.val < this.max/2){
                            if(this.val < 10){
                                var px = (myRange.offsetWidth * percent) +3
                            }
                            else if(this.val >= 10 && this.val < 100){
                                var px = (myRange.offsetWidth * percent) -5
                            }else if(this.val >= 100 && this.val < 1000){
                                var px = (myRange.offsetWidth * percent) -10
                            }else if(this.val >= 1000 && this.val < 10000){
                                var px = (myRange.offsetWidth * percent) -15
                            }else{
                                var px = (myRange.offsetWidth * percent) -20
                            }
                    }else if(this.val == this.max/2){
                            var px = (myRange.offsetWidth * percent) 
                    }else{
                            if(this.val < 10){
                                var px = (myRange.offsetWidth * percent) +10
                            }
                            else if(this.val >= 10 && this.val < 100){
                                var px = (myRange.offsetWidth * percent) -10
                            }else if(this.val >= 100 && this.val < 1000){
                                var px = (myRange.offsetWidth * percent) -15
                            }else if(this.val >= 1000 && this.val < 10000){
                                var px = (myRange.offsetWidth * percent) -20
                            }else{
                                var px = (myRange.offsetWidth * percent) -25
                            }
                    }
                    }
                    
                    myValue.parentElement.style.left = px + 'px';
                    myValue.parentElement.style.top = '19px';

                }, 10);
            }
        },

        myEventHandler(e) {
            if(this.showValue){
                this.getValuePosition()
            }  
        },

        change(event){
        }
    }
    
}

</script>
<style scoped>
.app-local-value{
    font-size: 14px;
}
</style>