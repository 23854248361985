<template>
    <div class="" v-bind:class="{'app-local-calendar-icon': value && clearable, 'app-local-clear-icon': value && clearable}">
            <label v-if="label" class="app-input-top-label">{{label}}</label><br v-if="label"/>
            <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
            <date-picker :id="id_datepicker"
                        v-model="data_field"
                        :type="type"
                        :format="format"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :disabled-date="disableDates"
                        :show-week-number="showWeeekNumber"
                        :range="range"
                        range-separator=" - "
                        :class="{'app-datepicker': true, 'app-datepicker-filled': data_field, 'app-datepicker-disabled': disabled, 'app-datepicker-error': cError}"
                        
                        @change="updateValue"
                        @close='closed()'
                        @blur="onBlur" 
                        @focus="onFocus"
                        @input-error="onError"
                        :clearable="clearable"
                        @calendar-change="onCalendarChange"
                        @open="onOpen"
                    >

                    <i slot="icon-calendar" v-if="loading == true && cError == false">
                        <b-spinner small class="app-spinner-size app-local-spinner"></b-spinner>
                    </i>
        
            </date-picker>
        <validation-provider v-slot="{ errors}" ref="provider" :rules="cValidatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <input type="hidden" name="date" v-model="data_field">
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
        </validation-provider>
    </div>
</template>
<script>
import { defaultFormat } from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import moment from "moment";
import AppMasks from "@core/scripts/AppMasks";


export default {
    
    components: {
        DatePicker ,
    },

    name: 'AppDate',

    props: {
        id: {type: String},
        value: {},
        name: { type: String },
        placeholder: { type: String, default: "DD-MM-JJJJ" },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        disabledDatesPass: null,
        disabledDatesFuture: null,
        disabledDatesRange: null,
        label: {type: String},
        additionalInfo: {type: String},
        format: {type: String, default: "DD-MM-YYYY"},
        showWeeekNumber: {type: Boolean, default: false},
        range: {type: Boolean, default: false},
        type: {type: String, default: "date"},
        isDefaultFormat: {type: Boolean, default: true},
        loading: {type: Boolean, default: false},
        clearable: {type: Boolean, default: false},
    },

    created() {
        this.id_datepicker = 'datepicker_' + this._uid;
        this.date_mask = this.format.replace(/[DdMmYHhSsw]/g, "#");

        if(this.value != null && this.isDefaultFormat == true){
            var day = this.value.substring(0, 2);
            var month = this.value.substring(3, 5);
            var year = this.value.substring(6, 10);

            this.data_field = new Date(year + "-" + month + "-" + day);
            this.error++;
        } else {
            this.data_field = this.value;
        }
    },

    mounted(){

        document.querySelector('#' + this.id_datepicker).addEventListener('keypress', this.onKeypress)
        document.querySelector('#' + this.id_datepicker).addEventListener('keyup', this.onKeyup)
        this.is_mounted = true;

    },

    computed: {
        cValidatorRules: {
            get() { 
                var rules = null;
                if(this.validatorRules){
                    rules = this.validatorRules;
                }
                
                if(this.disabledDatesPass){
                    rules = rules ? rules + '|disable-date-past:' + this.disabledDatesPass : 'disable-date-past:' + this.disabledDatesPass;
                }

                if(this.disabledDatesFuture){
                    rules = rules ? rules + '|disable-date-future:' + this.disabledDatesFuture : 'disable-date-future:' + this.disabledDatesFuture;
                }
                return rules;
            }
        },

        cError: {
            get(){
                if(this.is_mounted){
                    return this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '';
                } else {
                    return false;
                }
            }
        }
    },

    watch:{
        value: {
            handler: function(val){
                if(this.value != null && this.isDefaultFormat == true){
                    var day = this.value.substring(0, 2);
                    var month = this.value.substring(3, 5);
                    var year = this.value.substring(6, 10);

                    this.data_field = new Date(year + "-" + month + "-" + day);
                    }
            }
        }
    },
    
    data() {
        return {
            data_field: null,
            app_masks: new AppMasks(),
            id_datepicker: null,
            date_mask: null,
            is_mounted: false,
            months: {"januari" : "01", "februari" : "02", "maart" : "03", "april" : "04", "mei" : "05", "juni" : "06", "juli" : "07", "augustus" : "08", "september" : "09", "oktober" : "10", "november" : "11", "december" : "12",
                     "january" : "01", "february" : "02", "march" : "03", "april" : "04", "may" : "05", "june" : "06", "july" : "07", "august" : "08", "september" : "09", "october" : "10", "november" : "11", "december" : "12"},

            months_short: {"jan" : "01", "feb" : "02", "mrt" : "03", "apr" : "04", "mei" : "05", "juni" : "06", "juli" : "07", "aug" : "08", "sep" : "09", "okt" : "10", "nov" : "11", "dec" : "12",
                            "jan" : "01", "feb" : "02", "mar" : "03", "apr" : "04", "may" : "05", "june" : "06", "july" : "07", "aug" : "08", "sept" : "09", "oct" : "10", "nov" : "11", "dec" : "12"}
        };
    },


    methods: {
        onBlur(event) {
            this.$emit('blur', event.target.value);
        },

        async closed(){

            this.$emit('closed');
            await this.$nextTick();
            this.$refs.provider.validate();
        },

        disableDates(date) {
            const today = new Date();

            if(this.disabledDatesRange != null){
                return eval(this.disabledDatesRange)
            }else if(this.disabledDatesPass != null && this.disabledDatesFuture != null){
                return date < today.setDate(today.getDate() - parseInt(this.disabledDatesPass)) || date > today.setDate(today.getDate() + parseInt(this.disabledDatesFuture));
            }else if(this.disabledDatesPass != null){
                return date < today.setDate(today.getDate() - parseInt(this.disabledDatesPass));
            }else if(this.disabledDatesFuture != null){
                return date > today.setDate(today.getDate() + parseInt(this.disabledDatesFuture));
            }

            
            return null;
        },

        updateValue: function(value) {
            if(this.data_field != null && this.isDefaultFormat){
                this.$emit("input", moment(this.data_field).format('DD-MM-YYYY'));
            }else{
                this.$emit("input", this.data_field);
            }
        },

        onKeypress(event){
            
            if(event.target.value.toString().length < this.format.length ){
                var regex_mask = this.app_masks.getLetterMask(this.date_mask[event.target.selectionStart]);

                if(regex_mask != null){
                    var res = event.key.match(regex_mask);
                    if (!res) {
                        event.preventDefault();
                        return false;
                    }
                }
            }else{
                
                if(event.target.value.toString().length <= this.format.length && event.target.selectionStart < this.format.length){

                    if(event.target.selectionStart == 0 && event.target.selectionEnd == this.format.length){
                        event.target.value = event.target.value.slice(event.target.selectionStart, event.target.selectionEnd);
                    }else{
                        event.target.value = event.target.value.slice(0, -(this.format.length - event.target.selectionStart));
                    }
                    
                }else{
                    event.preventDefault();
                    return false;
                }
                
            }
            
        },

        onKeyup(event){
            
            if(event.target.value.toString().length < this.format.length && this.date_mask[event.target.selectionStart] == '-' && event.code != 'Backspace'){
                event.target.value =  event.target.value + '-';
            }

        },

        onFocus(event){
        },

        onError(event){
            

            var source = event.toString();
            source = source.trim();

            //replace moth string to integer
            for(const[key, value] of Object.entries(this.months)){
                source = source.replace(key, value);
            }


            //replace moth string to integer
            for(const[key, value] of Object.entries(this.months_short)){
                source = source.replace(key, value);
            }

            //remove all special char
            source = source.replace(/[^0-9]+/g, "");


            if(source.length >= 5 && source.length <= 8){

                if(source.length == 7 || source.length == 8){

                    source = String(source.substr(-4, 4)) + "-" + String(source.substr(-6, 2)) + "-" + String(source.substr(-8, source.length == 7 ? 1 : 2)).padStart(2, '0');

                }else{
                    source = String(source.substr(-2, 2)).padStart(4, '20') + "-" + String(source.substr(-4, 2)) + "-" + String(source.substr(-6, source.length == 5 ? 1 : 2)).padStart(2, '0');
                }

            }else if(source.length == 3 || source.length == 4){
                
                var day = String(source.substr(-4, source.length == 3 ? 1 : 2)).padStart(2, '0');
                var month = String(source.substr(-2, 2));

                const date = new Date();
                var year = null;

                if(parseInt(month) > date.getMonth() + 1){
                    year = date.getFullYear();
                }else{
                    year = date.getFullYear() + 1;
                }

                source = String(year) + "-" + String(month) + '-' + String(day);
            }else{
                source = null;
            }


            if(source == null){
                this.$emit("input", null);
            }else{
                this.data_field = new Date(source);
            
                if(isNaN(this.data_field)){
                    this.data_field = null;
                    this.$emit("input", null);
                }else{
                    this.$emit("input", moment(this.data_field).format('DD-MM-YYYY'));
                }
            }
                

            
            
            //this.$emit("input", moment(this.data_field).format('DD-MM-YYYY'));
            this.$emit('input-error', this.event);


            /*var day = event.substring(0, 2);
            var month = event.substring(3, 5);
            var year = event.substring(6, 10);

            this.data_field = new Date(year + "-" + month + "-" + day);
            this.$emit("input", moment(this.data_field).format('DD-MM-YYYY'));
            this.$emit('input-error', event);*/
        
        },

        onCalendarChange(event){
            this.$emit('calendar-change', event);
        },

        onOpen(event){
            this.$emit('open', event);
        }


    },
};
</script>

<style scoped>
.app-local-spinner{
    position: absolute;
    z-index: 10;
    right: 0px;
    top: 9px;
    border-width: 2px !important;
}
</style>
<style>
.app-local-calendar-icon .mx-icon-calendar{
    display:none;
}

.app-local-clear-icon .mx-icon-clear{
    display:initial;
}
</style>