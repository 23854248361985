<template>
  <div class="header">
     <b-container fluid>
      <div class="header-body">
        <slot></slot>
      </div>
     </b-container>
  </div>
</template>
<script>
  export default {
    name: 'hdr',
    props: {}
  }
</script>
<style>
</style>
