export default class envVariables {

    getBaseURLforAxios() {        
        //baseURL: 'https://app-tst-weu-backend.azurewebsites.net/service/public/app-main/',
        //baseURL: 'http://localhost:8000/app-main/',
        //baseURL: 'https://hn-service.idea3.pl/app-main/',
        //baseURL: 'https://app-tst-weu-backend.azurewebsites.net/app-main/',
        //baseURL: '10.0.1.7/app-main/',
        if (process.env.NODE_ENV !== 'production') {
            return 'http://localhost:8000/app-main/';

        }
        else {
            
            //HN TEST
            //return 'https://happyone-service-test.happynurse.nl/app-main/'

            //HN PRODUCTION
            //return 'https://happyone-service.happynurse.nl/app-main/'

            //azure default domain
            //return 'https://app-tst-weu-backend.azurewebsites.net/app-main/';
            //IDEA 3
            return 'https://hn-service.idea3.pl/app-main/'
        }
    };

    getBaseURLforEcho() {        
        if (process.env.NODE_ENV !== 'production') {
            return 'localhost';

        }
        else {
            return 'happyone-service-test.happynurse.nl'
        }
    };

    getURLforEchoAuthorization() {        
        if (process.env.NODE_ENV !== 'production') {
            return 'http://localhost:8000/broadcasting/auth';

        }
        else {
            return 'https://happyone-service-test.happynurse.nl/broadcasting/auth'
        }
    };

    getAuthDataForAad() {
        if (process.env.NODE_ENV !== 'production') {
            return {
                clientId: "e8ef3527-0e65-444e-a594-09672adc2bc2",
                authority: "https://login.microsoftonline.com/34b5d38d-fcbd-412c-b989-0c4d783b26f6",
                redirectURI: "http://localhost:8080"
            }
        }
        else {
            return {
                
                //HN TEST
                // clientId: "8aa2b9b6-4e16-4daf-b77b-80299eeb8609",
                // authority: "https://login.microsoftonline.com/9955ef6c-c681-428a-a9be-f83979fc1f04",
                // redirectURI: "https://happyone-test.happynurse.nl"

                //HN PRODUCTION
                /*clientId: "4cf02bb9-fcd1-4457-bf10-4fbc30c49fae",
                authority: "https://login.microsoftonline.com/9955ef6c-c681-428a-a9be-f83979fc1f04",
                redirectURI: "https://happyone.happynurse.nl"*/


                
                //azure default domain
                //redirectURI: "https://app-tst-weu-internal.azurewebsites.net"
                //IDEA 3
                clientId: "e8ef3527-0e65-444e-a594-09672adc2bc2",
                authority: "https://login.microsoftonline.com/34b5d38d-fcbd-412c-b989-0c4d783b26f6",
                redirectURI: "https://hn-app-internal.idea3.pl"
            }
        }
    };
}