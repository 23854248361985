<template>
    <app-base-nav container-classes="container-fluid" class="navbar-top border-bottom navbar-expand app-navbar">
        <!-- Search form 
        <div class="navbar-search form-inline" id="navbar-search-main">
           <search-component />
        </div>-->
        <b-navbar-nav class=" align-items-center mr-2" v-bind:class="{'auto-with': !display_menu}">       
            <search-component :displayMenu.sync="display_menu" />     
        </b-navbar-nav>
        

        <b-navbar-nav class="align-items-center ml-md-auto" v-bind:class="{'auto-hide': !display_menu}">
            <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
            <li class="nav-item d-xl-none">
                <!-- Sidenav toggler -->
                <div class="pr-3 sidenav-toggler" :class="{ active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light' }" @click="toggleSidebar">
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                    </div>
                </div>
            </li>
            <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add 
            <li class="nav-item d-sm-none">
                <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
                    <i class="ni ni-zoom-split-in"></i>
                </a>
            </li>
           -->
            <div class="mr-3 app-local-notifications" @click="goToActions()">
                <b-icon icon="bell-fill" style="width: 22px; height: 22px;"/> 
                <div class="app-local-badge-bell" v-if="notifications > 0"> {{notifications < 9 ? notifications : '9+'}} </div>
            </div>
             <b-dropdown variant="link" no-caret right toggle-class="p-0">
                <template #button-content>
                    <div class="b-avatar avatar avatar-sm rounded-circle badge-secondary rounded-circle">
                        {{userAvatarText}}
                    </div>
                </template>
                <b-dropdown-item @click="openProfile" class="app-local-dropdown-width">
                <b-row>
                    <b-col cols="2" class="align-middle">
                        <div class="b-avatar avatar avatar-sm rounded-circle badge-secondary rounded-circle font-weight-bolder">
                            {{userAvatarText}}
                        </div>
                    </b-col>
                    <b-col cols="10">
                        <div class="mb-0 app-local-height" >
                            <p class="font-weight-bolder mb-0">
                                {{ user_name }}
                            </p>
                            <span class="app-local-user-status"  v-overflow-tooltip>{{ user_email }}</span><br/>
                        </div>
                    </b-col>
                </b-row>
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item v-if="id_user_const_role == 1" @click="showTranslationKey">
                    <span v-if="show_translation_key == 'true'">{{trans('hide-translation-keys',6)}}</span>
                    <span v-if="show_translation_key == 'false'">{{trans('show-translation-keys', 6)}}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="logout">{{trans('log-out', 6)}}</b-dropdown-item>
            </b-dropdown>
        </b-navbar-nav>
    </app-base-nav>
   
</template>
<script>

import SearchComponent from '@core/components/AppQuickSearch.vue';
import AppBaseNav from '../../@core/components/AppBaseNav.vue';
import axios from "@axios";

export default {
    components: {
        AppBaseNav,
        SearchComponent,
        SearchComponent
    },
    props: {
        type: {
            type: String,
            default: "default", // default|light
            description: "Look of the dashboard navbar. Default (Green) or light (gray)",
        },
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },

    },

    created(){
        if(this.start == 0){
            this.getMyIssues();
            this.start++;
        }

        
    },

    mounted: function () {
        if(process.env.NODE_ENV != 'production'){
            this.$echo.private('private.notification.'+ JSON.parse(localStorage.getItem('user')).id).listen('.notification', (payload) => {
                this.$notify(payload.message);
            });
        }

        setInterval(() => {
            this.getMyIssues()
        }, 60000)
    },

    data() {
        return {
            userAvatarText: JSON.parse(localStorage.getItem('user')).shortcut_name,
            user_name: JSON.parse(localStorage.getItem('user')).user_name,
            user_email: JSON.parse(localStorage.getItem('user')).email,
            user_id: JSON.parse(localStorage.getItem('user')).id,
            id_user_const_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            show_translation_key: localStorage.getItem('showTranslationKey'),
            activeNotifications: false,
            notifications: 0,
            showMenu: false,
            searchModalVisible: false,
            display_menu: true,
            start: 0,
            disabled_route_names:[
                'Login',
                '404-error-page',
                '500-error-page'
            ]
        };
    },
    methods: {

        logout() {
          axios.post(
              "authorization/logout", {
                                          
                  })
              .then((response) => {
                  
              })
              .catch((error) => {
                      console.error(`error during authentication: ${error}`);
              });
              if(process.env.NODE_ENV != 'production'){
                this.$echo.leave('private.notification.'+ JSON.parse(localStorage.getItem('user')).id);
              }
              this.$routeStack.stack = [];
              localStorage.removeItem("user");
              localStorage.removeItem('routeStack');
              localStorage.removeItem('showTranslationKey');
              this.$router.push({ name: "Login" });
        },
        
        goToActions(){
            this.$router.push({ name: "issues-list"});
        },

        getMyIssues(){

            var pause = localStorage.getItem('pause');
            if(this.disabled_route_names && !this.disabled_route_names.includes(this.$route.name) && pause != 1){
                axios
                    .get("issue/getMyIssues")
                    .then((res) => {
                        this.notifications = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
            }
        },

        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        
        openProfile(){
            this.$router.push({ name: "users-my-profile" });
        },

        showTranslationKey(){
            this.show_translation_key = localStorage.getItem('showTranslationKey');

            if(this.show_translation_key == 'true'){
                this.show_translation_key = 'false';
            }else{
                this.show_translation_key = 'true';
            }

            localStorage.setItem('showTranslationKey',  this.show_translation_key);
            location.reload();
        }
    },
};
</script>
<style scoped>
.app-local-user-status {
    font-size: smaller;
    color: gray;
    padding-top:2px
}
p{
    line-height:1.1 !important;
}
.app-local-dropdown-width{
    width:270px !important
}

.app-local-height{
    max-height:37px;
    min-height:37px
}

.app-local-badge-bell {
    font-size: 10px;
    position: absolute;
    left: 10px;
    top: -6px;
    color: white;
    background: #F04438;
    border: 2px solid #f6f8fa;
    border-radius: 20px;
    padding: 2px 6.5px !important;
}

.app-local-badge-medium{
    padding: 2.4px 4.4px !important;
}
.app-local-badge-large{
    font-size: 9px;
    padding: 3px 2px !important;
}
.app-local-notifications{
    cursor: pointer;
    position: relative;
}

@media (max-width: 660px){
    .auto-hide {
            display: none !important;
    }
    .auto-with{
        width: 100%;
        margin-right: 0px !important;
    }
}
/* .navbar-search{
    max-width: 340px;
    width: 340px;
} */
</style>