import clickOutside from '@/directives/click-ouside.js';
import tooltip from '@/directives/tooltip.js';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('uppercase', {update (el, binding){
                                             if(binding.arg){el.value = el.value.toUpperCase()}
    }});
    Vue.directive('overflow-tooltip', tooltip);
  }
};

export default GlobalDirectives;
