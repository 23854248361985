import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import Page404 from '@/views/404Page.vue';
import Page500 from '@/views/500Page.vue';
import Page403 from '@/views/403Page.vue';
import translation from "../util/translations.js";
import { transform } from 'd3';
var tranObj = new translation();
// Dashboard pages
const Dashboard = () => import('@app/Dashboards/Dashboards.vue');

// Pages
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Login.vue');

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [    
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    { 
      path: '*',
      name: '404-error-page',
      component: Page404
    },
    {
      path: "/500-error",
      name: "500-error-page",
      component: Page500
    },

    {
      path: "/403-error",
      name: "403-error-page",
      component: Page403
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Dashboard',
  },

  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {name_nl: 'rt-dashboard'}
      },

      {
        path: 'dashboard/compliancy-check',
        name: 'dashboard-compliancy-check',
        component: () => import('@app/Dashboards/Components/CompliancyCheck/DashboardCompliancyCheckComponent.vue'),
        meta: {name_nl: 'rt-conformity-check'}
      },
      
      //Lista aplikanta - Sollicitanten
      {
        path: '/people/applicants/list',
        name: 'people-applicants-list',
        component: () => import('@app/People/Applicants/PeopleApplicantsList.vue'),
        meta: {name_nl: 'rt-applicants'}
      },

    
      //Lista pracownika - Flexwerkers
      {
        path: '/people/employees/list',
        name: 'people-employees-list',
        component: () => import( '@app/People/Employee/PeopleEmployeeList.vue'),
        meta: {name_nl: 'rt-flexworkers'}
      },

      {
        path: '/people/profile/history/:id_person_data_main',
        name: 'people-profile-history',
        component: () => import('@app/People/Profile/History/PeopleProfileHistoryList.vue'),
        meta: {name_nl: 'rt-history'}
      },

      {
        path: '/people/profile/webforms/:id_person_data_main',
        name: 'people-profile-webforms',
        component: () => import('@app/People/Profile/WebForms/PeopleProfileWebFormsList.vue'),
        meta: {name_nl: 'rt-web-forms'}
      },

      

      {
        path: '/people/applicants/profile/wizard/:id_person_data_main',
        name: 'people-applicants-profile-wizard',
        component: () => import('@app/People/Applicants/Profile/Wizard/PeopleApplicantsProfileWizard.vue'),
        meta: {name_nl: 'rt-data-wizard'}
      },

      {
        path: '/people/applicants/profile/functions/:id_person_data_main',
        name: 'people-applicants-profile-status-bar-functions',
        component: () => import('@app/People/Profile/StatusBar/PeopleProfileStatusBarFunctionsChange.vue')
      },

      //Karta aplikanta
      {
        path: '/people/person/profile/:id_person_data_main',
        name: 'people-person-profile',
        component: () => import('@app/People/Profile/PeoplePersonProfile.vue')
      },

      //Person mobile
      {
        path: '/people/person/profile/mobile/:id_person_data_main',
        name: 'people-profile-mobile',
        component: () => import('@app/People/Profile/Mobile/PeoplePersonProfileMobile.vue')
      },

      //To do - główne menu
      {
        path: "issues",
        name: 'issues-list',
        component: () => import('@app/Issues/IssuesList.vue'),
        meta: {name_nl: 'rt-actions'}
      },

      /* CLIENTS ROUTES */
      //Lista prospektów - prospects
      {
        path: '/clients/prospects',
        name: 'clients-prospects-list',
        component: () => import('@app/Clients/ClientsProspectsList.vue'),
        meta: {name_nl: 'rt-prospect-list'}
      },

      {
        path: '/clients/suspects',
        name: 'clients-suspect-list',
        component: () => import('@app/Clients/ClientSuspectList.vue'),
        meta: {name_nl: 'rt-suspect'}
      },


      {
        path: '/client/prospects/wizard/:id_client_data_main',
        name: 'clients-prospect-wizard',
        component: () => import('@app/Clients/Prospect/Wizard/ClientsProspectWizard.vue'),
        meta: {name_nl: 'rt-data-wizard'}
      },

      {
        path: '/clients/list',
        name: 'clients-list',
        component: () => import('@app/Clients/ClientsList.vue'),
        meta: {name_nl: 'rt-client-list'}
      },
      
      /* Organization profile */
      {
        path: '/clients/organization/:id_client_data_main',
        name: 'clients-profile-organization',
        component: () => import('@app/Clients/Profile/ClientsProfileOrganization.vue'),
        meta: {name_nl: 'rt-organization'}
      },

      {
        path: '/clients/organization/webforms/:id_client_data_main',
        name: 'clients-profile-webforms',
        component: () => import('@app/Clients/Profile/Organization/WebForm/ClientProfileWebFormsList.vue'),
        meta: {name_nl: 'rt-web-forms'}
      },

      {
        path: '/clients/organization/history/:id_client_data_main',
        name: 'clients-organization-history',
        component: () => import('@app/Clients/Profile/Organization/ClientsProfileOrganizationHistoryList.vue'),
        meta: {name_nl: 'rt-history'}
      },
     
      /* Location profile */
      {
        path: '/clients/location/:id_location_data_main',
        name: 'clients-profile-location',
        component: () => import('@app/Clients/Profile/ClientsProfileLocation.vue'),
        meta: {name_nl: 'rt-location'}
      },

      {
        path: '/clients/location/history/:id_location_data_main',
        name: 'clients-location-history',
        component: () => import('@app/Clients/Profile/Location/ClientsProfileLocationHistoryList.vue'),
        meta: {name_nl: 'rt-history'}
      },

      /* Department profile */

      {
        path: '/clients/department/:id_department_data_main',
        name: 'clients-profile-department',
        component: () => import('@app/Clients/Profile/ClientsProfileDepartment.vue'),
        meta: {name_nl: 'rt-department'}
      },
      
      {
        path: '/clients/department/history/:id_department_data_main',
        name: 'clients-department-history',
        component: () => import('@app/Clients/Profile/Department/ClientsProfileDepartmentHistoryList.vue'),
        meta: {name_nl: 'rt-history'}
      },

      {
        path: '/client/profile/contract/wizard/:id_client_data_main',
        name: 'clients-profile-contract-wizard',
        component: () => import('@app/Clients/Profile/Contract/Wizard/ClientsProfileContractWizard.vue'),
        meta: {name_nl: 'rt-create-contract'}
      },

      {
        path: '/client/profile/contract/indexation',
        name: 'clients-profile-contract-indexation',
        component: () => import('@app/Clients/Profile/Contract/ClientsProfileOrganizationContractIndexation.vue'),
        meta: {name_nl: 'rt-index-contract'}
      },

      /*END CLIENTS ROUTES */

      /*PLANNING ROUTES*/

      {
        path: '/planning/offers',
        name: 'planning-offers',
        component: () => import('@app/Planning/Offers/OffersList.vue'),
        meta: {name_nl: 'rt-job-offer'}
      },

      {
        path: '/planning/planner',
        name: 'planning-planner',
        component: () => import('@app/Planning/Planner/PlanningPlanner.vue'),
        meta: {name_nl: 'rt-planner'}
      },

      {
        path: '/planning/offers/shifts/add',
        name: 'planning-offers-shifts-add',
        component: () => import('@app/Planning/Offers/Shifts/Add/OffersShiftsAdd.vue'),
        meta: {name_nl: 'rt-create-service'}
      },

      {
        path: '/planning/offers/shifts/add/:id_shift_data_main',
        name: 'planning-offers-shifts-add-copy',
        component: () => import('@app/Planning/Offers/Shifts/Add/OffersShiftsAdd.vue'),
        meta: {name_nl: 'rt-create-service'}
      },

      {
        path: '/planning/offers/shifts/send-notifications/:id_shift_data_main',
        name: 'planning-offers-shifts-send-notifications',
        component: () => import('@app/Planning/Offers/Shifts/OffersShiftsListSendNotification.vue'),
        meta: {name_nl: 'planning-send-notifications'}
      },

      {
        path: '/planning/offers/shifts/add-bulk',
        name: 'planning-offers-shifts-add-bulk',
        component: () => import('@app/Planning/Offers/Shifts/Add/OffersShiftsAddBulk.vue'),
        meta: {name_nl: 'rt-create-service-bulk'}
      },

      {
        path: '/planning/offers/shifts/add-person',
        name: 'planning-shifts-add-person',
        component: () => import('@app/Planning/Offers/Shifts/PersonAdd/OffersShiftsListPersonAdd.vue'),
        meta: {name_nl: 'rt-flexworker-sign-up'}
      },

      {
        path: '/planning/offers/assignments/add-person',
        name: 'planning-assignments-add-person',
        component: () => import('@app/Planning/Offers/Assignments/PersonAdd/OffersAssignmentsListPersonAdd.vue'),
        meta: {name_nl: 'rt-flexworker-sign-up'}
      },

      {
        path: '/planning/shifts/profile/:id_shift_data_main',
        name: 'planning-shifts-profile',
        component: () => import('@app/Planning/Shifts/Profile/ShiftsProfile.vue'),
        meta: {name_nl: 'rt-services'}
      },

      {
        path: '/planning/assignments/profile/:id_assignment_data_main',
        name: 'planning-assignments-profile',
        component: () => import('@app/Planning/Assignments/Profile/AssignmentsProfile.vue'),
        meta: {name_nl: 'rt-secondments'}
      },

      {
        path: '/planning/registrations',
        name: 'planning-registrations',
        component: () => import('@app/Planning/Registrations/RegistrationsList.vue'),
        meta: {name_nl: 'rt-registrations'}
      },

      {
        path: '/planning/queue',
        name: 'planning-registrations-shifts-queue',
        component: () => import('@app/Planning/Queue/QueueList.vue'),
        meta: {name_nl: 'rt-notification queue'}
      },

      {
        path: '/planning/placements',
        name: 'planning-placements',
        component: () => import('@app/Planning/Placements/PlacementsList.vue'),
        meta: {name_nl: 'rt-placements'}
      },
      
      {
        path: '/planning/placements/profile/:id_placement_data_main',
        name: 'planning-placements-profile',
        component: () => import('@app/Planning/Placements/Profile/PlacementsProfile.vue'),
        meta: {name_nl: 'rt-placement'}
      },

      {
        path: '/planning/placements/profile/calculation/change',
        name: 'planning-placements-profile-calculation-change',
        component: () => import('@app/Planning/Placements/Profile/Components/PlacementsProfileCalculationChange.vue'),
        meta: {name_nl: 'rt-placement-calculation'}
      },

      {
        path: '/planning/offers/assignments/add',
        name: 'planning-offers-assignments-add',
        component: () => import('@app/Planning/Offers/Assignments/Add/OffersAssignmentsAdd.vue'),
        meta: {name_nl: 'rt-secondments'}
      },

      {
        path: '/planning/hours',
        name: 'planning-hours',
        component: () => import('@app/Planning/Hours/HoursList.vue'),
        meta: {name_nl: 'rt-declaration-services'}
      },

      {
        path: '/planning/hours/profile/:id_hour_data_main',
        name: 'planning-hours-profile',
        component: () => import('@app/Planning/Hours/Profile/HoursProfile.vue'),
        meta: {name_nl: 'rt-declaration-services'}
      },
/*
      {
        path: '/planning/shifts/add',
        name: 'planning-shifts-add',
        component: () => import('@app/Planning/Shifts/Add/ShiftsAdd.vue')
      },

      {
        path: '/planning/shifts/add-bulk',
        name: 'planning-shifts-add-bulk',
        component: () => import('@app/Planning/Shifts/Add/ShiftsAddBulk.vue')
      },

      {
        path: '/planning/assignments/add',
        name: 'planning-assignments-add',
        component: () => import('@app/Planning/Assignments/Add/AssignmentsAdd.vue')
      },

      {
        path: '/planning/shifts/profile/:id_shift_data_main',
        name: 'planning-shifts-profile',
        component: () => import('@app/Planning/Shifts/Profile/ShiftsProfile.vue')
      },

      {
        path: '/planning/assignments/profile/:id_assignment_data_main',
        name: 'planning-assignments-profile',
        component: () => import('@app/Planning/Assignments/Profile/AssignmentsProfile.vue')
      },

      {
        path: '/planning/registrations',
        name: 'planning-placements',
        component: () => import('@app/Planning/PlanningRegistrationsList.vue')
      },
      */

     


        /* END PLANNING ROUTES*/

      /* SETTINGS ROUTES */
      {
        path: '/settings/users',
        name: 'users',
        component: () => import('@app/Settings/Users/SettingsUsersList.vue'),
        meta: {name_nl: 'rt-users'}
      },

      {
        path: '/settings/users/sections',
        name: 'sections',
        component: () => import('@app/Settings/Section/SettingsSectionList.vue'),
        meta: {name_nl: 'rt-cao'}
      },

      {
        path: '/settings/users/sections/profile/:id_cao_data_main',
        name: 'sections-profile',
        component: () => import('@app/Settings/Section/Profile/SettingsSectionProfile.vue'),
        meta: {name_nl: 'rt-cao-profile'}
      },

      {
        path: '/settings/users/sections/profile/schedule/add/:id_cao_data_schedule',
        name: 'sections-profile-schedule-add',
        component: () => import('@app/Settings/Section/Profile/CaoSchedule/SettingsSectionProfileCaoScheduleAdd.vue'),
        meta: {name_nl: 'rt-cao-schedule-add'}
      },

      {
        path: '/settings/users/types',
        name: 'types',
        component: () => import('@app/Settings/Type/SettingsTypeList.vue'),
        meta: {name_nl: 'rt-functions'}
      },

      {
        path: '/settings/easyflex',
        name: 'easyflex',
        component: () => import('@app/Settings/Easyflex/SettingsEasyFlexQueueList.vue'),
        meta: {name_nl: 'rt-easyflex'}
      },

      {
        path: '/settings/api',
        name: 'api',
        component: () => import('@app/Settings/Api/SettingApiList.vue'),
        meta: {name_nl: 'rt-api'}
      },

      {
        path: '/settings/translations',
        name: 'translations',
        component: () => import('@app/Settings/Translations/SettingTranslationsList.vue'),
        meta: {name_nl: 'rt-translations'}
      },

      {
        path: '/settings/users/profile',
        name: 'users-my-profile',
        component: () => import('@app/Settings/Users/Profile/SettingsUsersProfile.vue'),
        meta: {name_nl: 'rt-user'}
      },

      {
        path: '/settings/users/profile/:id_user',
        name: 'users-profile',
        component: () => import('@app/Settings/Users/Profile/SettingsUsersProfile.vue'),
        meta: {name_nl: 'rt-user'}
      },
      {
        path: '/settings/users/profile/logs/:id_user',
        name: 'settings-user-logs-list',
        component: () => import('@app/Settings/Users/Profile/Logs/SettingsUsersProfileLogsList.vue'),
        meta: {name_nl: 'rt-history'}
      },
      {
        path: '/settings/offices',
        name: 'offices',
        component: () => import('@app/Settings/Offices/SettingsOfficesList.vue'),
        meta: {name_nl: 'rt-offices'}
      },
      {
        path: '/settings/offices/locations/:id_office_data_main',
        name: 'locations',
        component: () => import('@app/Settings/Offices/Locations/SettingsOfficesLocationsList.vue'),
        meta: {name_nl: 'rt-locations'}
      },
      {
        path: '/settings/documents/templates',
        name: 'documents-templates',
        component: () => import('@app/Settings/Documents/SettingsDocumentsTemplates.vue'),
        meta: {name_nl: 'rt-document-templates'}
      },
      {
        path: '/settings/variables',
        name: 'variables',
        component: () => import('@app/Settings/Variables/SettingsVariablesList.vue'),
        meta: {name_nl: 'rt-system variables'}
      },
      {
        path: '/settings/queue',
        name: 'queue',
        component: () => import('@app/Settings/Queue/SettingsQueueList.vue'),
        meta: {name_nl: 'rt-queues'}
      },
      {
        path: '/settings/emails-sent',
        name: 'emails-sent',
        component: () => import('@app/Settings/EmailsSent/SettingsEmailsSentList.vue'),
        meta: {name_nl: 'rt-emails'}
      },
      {
        path: '/settings/roles',
        name: 'roles',
        component: () => import('@app/Settings/Roles/SettingsRolesList.vue'),
        meta: {name_nl: 'rt-roll'}
      },
      {
        path: '/settings/settings',
        name: 'settings',
        component: () => import('@app/Settings/Settings.vue'),
        meta: {name_nl: 'rt-settings'}
      },
      {
        path: '/settings/users/profile/signature/change',
        name: 'user-signature-pad',
        component: () => import('@app/Settings/Users/Profile/Signature/SettingsUsersProfileSignatureChange.vue'),
        meta: {name_nl: 'rt-signature'}
      },
      {
        path: '/settings/errors',
        name: 'errors',
        component: () => import('@app/Settings/Errors/SettingsErrorsList.vue'),
        meta: {name_nl: 'rt-error-list'}
      },

      {
        path: '/settings/freeDays',
        name: 'freeDays',
        component: () => import('@app/Settings/FreeDays/SettingsFreeDaysList.vue'),
        meta: {name_nl: 'rt-national-holidays'}
      },
      
      {
        path: '/settings/changeLog',
        name: 'change-log',
        component: () => import('@app/Settings/ChangeLog/SettingsChangeLogList.vue'),
        meta: {name_nl: 'rt-change-log'}
      },

      {
        path: '/settings/infiniteScroll',
        name: 'infinite-scroll',
        component: () => import('@app/Settings/SettingsInfiniteScrollList.vue'),
        meta: {name_nl: 'rt-error-list'}
      },

      {
        path: '/settings/importData',
        name: 'import-data',
        component: () => import('@app/Settings/ImportData/ImportData.vue'),
        meta: {name_nl: 'rt-import-data'}
      },

      {
        path: '/settings/easyflexImport',
        name: 'easyflex-import',
        component: () => import('@app/Settings/EasyflexImport/EasyflexImport.vue'),
        meta: {name_nl: 'rt-easyflex-import'}
      },

      {
        path: '/settings/documentsReport',
        name: 'documents-report',
        component: () => import('@app/Settings/DocumentsReport/DocumentsReportList.vue'),
        meta: {name_nl: 'rt-documents-report'}
      },


      /* END SETTINGS ROUTES */
    ]
  },

  authPages,
];

export default routes;
