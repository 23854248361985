<template>
    <div class="app-local-navbar-sticky">
        <div class="pl-3 app-local-navbar app-local-navbar-sticky" v-if="name_nl && !isWizard() && name_nl != 'Login'" v-bind:class="{'app-local-navbar-sticky-padding': !isProfile()}">
            <div class=" d-flex align-items-center">

            <router-link :to="goBack()" class="d-flex align-items-center" v-if="!client_type && !person_type && currentRoute.name != 'clients-profile-location' && currentRoute.name != 'clients-profile-department'">
                <b-icon icon="arrow-left" class="mr-2"></b-icon>
                <span v-if="route.name == 'clients-list' && currentRoute.name == 'clients-profile-organization'">{{trans('to-the-client-list',200)}}</span>
                <span v-else-if="route.name == 'clients-prospects-list' && currentRoute.name == 'clients-profile-organization'">{{trans('to-prospect-list',200)}}</span>
                <span v-else-if="(route.name == 'people-applicants-list' || route.name == 'people-employees-list') && currentRoute.name == 'people-person-profile'">{{trans('to-overview',200)}}</span>
                <!-- <span v-else-if="route.name == 'planning-offers' && currentRoute.name == 'planning-shifts-profile'">{{trans('to-services',200)}}</span> -->
                <span v-else-if="route.name == 'planning-placements' && currentRoute.name == 'planning-placements-profile'">{{trans('to-placements',200)}}</span>
                <span v-else-if="route.name == 'users' && currentRoute.name == 'users-profile'">{{trans('to-overview-users',200)}}</span>
                <span v-else-if="route.name == 'planning-registrations' && currentRoute.name == 'planning-registrations-shifts-queue'">{{trans('to-registrations',200)}}</span>
                <span v-else-if="route.name == 'sections' && currentRoute.name == 'sections-profile'">{{trans('to-overview-cao',200)}}</span>
                <span v-else> {{trans('back-to',200)}} {{name_nl}}</span>
            </router-link>

            <router-link :to="goBack()" class="d-flex align-items-center" v-if="(client_type || person_type) && currentRoute.name != 'clients-profile-location' && currentRoute.name != 'clients-profile-department'">
                <b-icon icon="arrow-left" class="mr-2"></b-icon>
                {{trans('back-to',200)}} {{cName}}
            </router-link>

            <!-- -----------------------location-profile----------------------------------- -->
            <div class="mt-1 mb-1 d-flex align-items-center" v-if="currentRoute.name == 'clients-profile-location' && client_path != '/'">

                <router-link :to="goBack()" class="d-flex align-items-center" v-if="route.path != client_path">
                    <b-icon icon="arrow-left" class="mr-2"></b-icon>
                    {{trans('back-to',200)}} {{name_nl}}
                </router-link>

                <span class="mr-2 ml-2 text-muted" v-if="route.path != client_path"> | </span>

                <router-link :to="client_path">
                    <b-icon icon="arrow-left" class="mr-2" v-if="route.path == client_path"></b-icon>
                    {{trans('to-parent-organization',200)}}
                </router-link>
            </div>
            <!-- ---------------------------------------------------------------------------- -->


            <!-- -----------------------department-profile----------------------------------- -->
            <div class="mt-1 mb-1 d-flex align-items-center" v-if="currentRoute.name == 'clients-profile-department' && location_path != '/'">
                <router-link :to="goBack()" class="d-flex align-items-center" v-if="route.path != location_path">
                    <b-icon icon="arrow-left" class="mr-2"></b-icon>
                    {{trans('back-to', 200)}} {{name_nl}}
                </router-link>

                <span class="mr-2 ml-2 text-muted" v-if="route.path != location_path"> | </span>

                <router-link :to="location_path" class="d-flex align-items-center">
                <b-icon icon="arrow-left" class="mr-2" v-if="route.path == location_path"></b-icon>
                {{trans('to-parent-location',200)}}
                </router-link>

                <span class="mr-2 ml-2 text-muted"> | </span>

                <router-link :to="client_path">
                {{trans('to-parent-organization',200)}}
                </router-link>
            </div>
            <!-- ---------------------------------------------------------------------------- -->

            </div>
        </div>
        <div v-else class="app-local-empty app-local-navbar">
                &nbsp;
        </div>
    </div>
</template>
<script>

export default {

    name: "AppNavigator",

    props:{
        currentRoute: {type: Object, default: () => {}},
    },

    components: {

    },

    created(){
        this.getStack();
        this.$root.$refs.AppNavigator = this;
        this.setPath();
    },
    

    watch: {
        currentRoute: {
            handler: function(){
                this.setPath();
                this.checkPath();
                this.saveStack();
            },
            deep: true
        },
    },

    computed: {
        
        client_type: {
            get() { return this.route.meta.client_type ? this.route.meta.client_type : null; },
        },

        person_type: {
            get() { return this.route.meta.person_type ? this.route.meta.person_type : null; },
        },

        cName: {
            get(){
                if(this.client_type && this.client_type == 1){
                    return 'prospect'
                }
                if(this.client_type && this.client_type == 2){
                    return 'klant'
                }
                if(this.person_type && this.person_type == 1){
                    return 'sollicitant'
                }
                if(this.person_type && this.person_type == 2){
                    return 'flexwerker'
                }

                return '';
            }
        },
    },

    data(){
        return{
            path: '',
            name_nl: '',
            name: '',
            route: null,
            client_path: '/',
            location_path: '/',
            params: {},
            wizards: [
                'people-applicants-profile-wizard',
                'clients-prospect-wizard',
                'clients-profile-contract-wizard',
                'planning-offers-shifts-add',
                'planning-offers-assignments-add',
                'planning-shifts-add-person',
                'planning-assignments-add-person',
                'planning-offers-shifts-add-bulk',
                'planning-offers-shifts-add-copy'
            ],
            profiles: [
                'clients-profile-organization',
                'clients-profile-location',
                'clients-profile-department',
                'people-person-profile',
                'planning-shifts-profile',
                'planning-placements-profile',
                'users-profile',
                'sections-profile'
            ],
            add_wizards: [
                'planning-shifts-add-person'
            ]
        }
    },

    methods: {

        setPath(){
            if(this.$routeStack.stack.length > 0){
                this.route = this.$routeStack.stack.slice(-1)[0];
                this.path = this.route.path;
                this.name_nl = this.route.meta.name_nl ? this.trans(this.route.meta.name_nl, 264) : this.route.name;
                this.params = JSON.parse(JSON.stringify(this.route.params));
                this.name = this.route.name;
            } else {
                this.path = '/';
                this.name_nl = '';
                this.name = '';
            }
            this.client_path = "/";
            this.location_path = "/";
        },

        isWizard(){
            if(this.wizards.includes(this.currentRoute.name)){
                return true;
            } else {
                return false;
            }
        },

        goBack(){
            return {name: this.name, params: this.params};
        },

        updatePath(client_path, location_path){
            
            this.client_path = client_path;
            this.location_path = location_path;
        },

        checkPath(){
            if(this.add_wizards.includes(this.route.name)){
                this.$routeStack.stack.pop();
                this.setPath();
            }
        },

        saveStack(){
            localStorage.setItem('routeStack', JSON.stringify(this.$routeStack.stack));
        },

        getStack(){
            var stack = JSON.parse(localStorage.getItem('routeStack'));
            if(stack != null){
                this.$routeStack.stack = stack;
            }
        },

        isProfile(){
            return this.profiles.includes(this.currentRoute.name);
        }
    },
    
}
</script>

<style>
.app-local-navbar-sticky{
    position: sticky;
    top: 71px;
    z-index: 50;
}

.app-local-navbar-sticky-padding{
    padding-bottom: 16px;
}


</style>
<style scoped>

</style>