<template>
    <div v-bind:class="{'app-local-calendar-icon': value && clearable, 'app-local-clear-icon': value && clearable}">
        <label v-if="label" class="app-input-top-label">{{label}}</label><br v-if="label"/>
        <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
        <date-picker v-model="periodField"
                    :type="type"
                    :format="format"
                    :value-type="valueType"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :disabled-date="disableCurrentPeriod"
                    style="padding-left: 5px !important"
                    popup-class="app-local-datepicker-period"
                    :class="{'app-datepicker': true, 'app-datepicker-filled': periodField, 'app-datepicker-disabled': disabled, 'app-datepicker-error': cError}"
                    @close='closed()'
                    @change="updateValue"
                    @blur="onBlur" 
                    :clearable="clearable"
                    >
            <i slot="icon-calendar" v-if="loading && errors.length == 0" />

            <template slot="input">
                <input v-model="visible_value" class="app-local-input" placeholder="Period" v-mask="'####-##'" @change="checkPeriod" />
            </template>

        </date-picker>

        <validation-provider v-slot="{ errors}" ref="provider" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <input type="hidden" name="date" v-model="periodField">
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
        </validation-provider>
    </div>
</template>
<script>
import { defaultFormat } from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import moment from "moment";

export default {
    
    components: {
        DatePicker ,
    },

    name: 'AppPeriod',

    props: {
        id: {type: String},
        value: {},
        name: { type: String },
        placeholder: { type: String, default: "Week" },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        additionalInfo: {type: String},
        format: {type: String, default: "YYYY-w"},
        valueType: {type: String, default: "YYYY-w"},
        type: {type: String, default: "week"},
        loading: {type: Boolean, default: false},
        clearable: {type: Boolean, default: false},
        periodFirstWeek: {type: String, default: null},
    },

    created() {
        if(this.value != null){
            this.periodField = this.value;
            let period = 0;

            let year = this.periodField.substring(0, this.periodField.indexOf('-'));
            let week = this.periodField.substring(this.periodField.indexOf('-') + 1);

            period = Math.floor((week-1) / 4) + 1;

            if(period > 0 && period <= 13){
                let date = moment().day("Monday").year(year).isoWeek((period * 4) - 3).toDate()
                let isoWeek = moment(date).isoWeek();

                this.cPeriodFirstWeek = year + '-' + isoWeek;
                this.visible_value = year + '-' + period;
                this.last_visible_value = this.visible_value
            }
            this.disableCurrentPeriod();
        }
    },

    mounted(){ 
        this.is_mounted = true;
    },

    computed: {
        cError: {
            get(){
                if(this.is_mounted){
                    return this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '';
                } else {
                    return false;
                }
            }
        },

        cPeriodFirstWeek:{
            get() {return this.periodFirstWeek },
            set(value) {    
                this.$emit('update:periodFirstWeek', value) 
            },
        }
    },

    watch:{
        value:{
            handler: function(val){
                this.periodField = this.value;
                let period = 0;

                let year = this.periodField.substring(0, this.periodField.indexOf('-'));
                let week = this.periodField.substring(this.periodField.indexOf('-') + 1);

                period = Math.floor((week-1) / 4) + 1;

                if(period > 0 && period <= 13){
                    let date = moment().day("Monday").year(year).isoWeek((period * 4) - 3).toDate()
                    let isoWeek = moment(date).isoWeek();

                    this.cPeriodFirstWeek = year + '-' + isoWeek;
                    this.visible_value = year + '-' + period;
                    this.last_visible_value = this.visible_value

                }
                this.visible_value = this.last_visible_value;
                this.disableCurrentPeriod();
            }
        },
    },
    
    data() {
        return {
            periodField: null,
            is_mounted: false,
            visible_value: null,
            last_visible_value: null,
            disabledPeriod: null
        };
    },


    methods: {
        onBlur(event) {
            this.$emit('blur', event.target.value);
        },

        async closed(){

            await this.$nextTick();
            this.$refs.provider.validate();
        },

        updateValue: function(value) {
            if(this.periodField != null){
                this.$emit("input", this.periodField);
            }else{
                this.$emit("input", this.periodField);
            }
        },

        disableCurrentPeriod(date) {
            
            return eval('(date >= new Date('+moment(this.periodFirstWeek,"YYYY-W")+')) && (date <= new Date('+moment(this.periodFirstWeek,"YYYY-W").add(4, 'w').subtract(1, 'd') +'))');
        },

        checkPeriod(){
            if(this.visible_value != null && /([0-9]{4})-([0-9]{1})([0-9]{1})?$/.test(this.visible_value)){
                let period = 0;
                let real_week = 0;
                let year = this.visible_value.substring(0, this.visible_value.indexOf('-'));
                let week = this.visible_value.substring(this.visible_value.indexOf('-') + 1);

                period = Math.floor((week-1) / 4) + 1;

                if(week > 0 && week <= 13){
                    real_week = (week * 4) - 3;
                    this.periodField = year + '-' + real_week;
                    this.cPeriodFirstWeek = year + '-' + real_week;
                }else{
                    this.visible_value = this.last_visible_value
                }
            }else{
                this.visible_value = this.last_visible_value;
            }
            this.$emit("input", this.periodField);
            this.disableCurrentPeriod();
        }
    },
};
</script>

<style scoped>
.app-local-calendar-icon .mx-icon-calendar{
    display:none;
}

.app-local-clear-icon .mx-icon-clear{
    display:initial;
}

.app-local-input{
    height: 29.3px;
    width: 99%;
    border: none;
    font-size: 0.875rem;
    color: #174066;
}

</style>

<style>

    .app-local-datepicker-period .mx-calendar-content .cell.disabled{
        color: white;
        background-color: #065070;
        cursor: auto !important;
    }

    .app-local-datepicker-period .mx-calendar-content .cell.disabled:hover{
        color: white !important;
        background-color: #065070 !important;
    }

    .app-local-datepicker-period .mx-calendar-content .cell:hover {
        color: white !important;
        background-color: #065070 !important;
    }

    .app-local-datepicker-period .mx-active-week, .mx-active-week .cell:hover, .mx-active-week .not-current-month .mx-week-number{
        color: #174066 !important;
        background-color: white !important;
        cursor: auto !important;
    }

    .app-local-datepicker-period .mx-week-number{
        cursor: auto !important;
    }
</style>