import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('user');
  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' , query: { redirect: to.fullPath } })
  //if (to.name === 'Login' && isAuthenticated) next({ name: 'Dashboard'})
  else next()

  if(Vue.prototype.$routeStack.stack.slice(-1)[0] && ((Vue.prototype.$routeStack.stack.slice(-1)[0].path == to.path) || ( 'deleted' in from.params && from.params.deleted) || from.name == '403-error-page')){
    Vue.prototype.$routeStack.stack.pop();
  } else{
    Vue.prototype.$routeStack.stack.push({
      path: from.path,
      name: from.name,
      meta: from.meta,
      params: from.params,
    });
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router;
