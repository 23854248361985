import Vue from 'vue'

export default class AppMasks extends Vue {

    getRegexMask(mask){
            
        var array_mask = {
                "lettersE1": /[a-zA-Z'\- À-ž]/,
                "lettersE2": /[a-zA-Z'\-'À-ž]/,
                "lettersE3": /[a-zA-Z0-9À-ž]/,
                "lettersE4": /[a-zA-Z À-ž]/,
                "lettersE5": /[a-zA-Z0-9 À-ž]/,
                "lettersE6": /[a-zA-Z0-9 '\-!@#$%^&*()_{}?<>,.;:=+|/À-ž]/,
                "lettersE7": /[a-zA-Z0-9*#+À-ž]/,
                "lettersE8": /[a-zA-Z À-ž.-]/,
                "lettersE9": /[a-zA-Z0-9\- À-ž]/,
                "letters": /[a-zA-ZÀ-ž]/,
                "date": /[0-9-]/,

                "#": /[0-9]/,
                "A": /[a-zA-Z]/,
                "N": /[a-zA-Z0-9]/,

        }
        
        return array_mask[mask];
    }

    getBTWMask(){
        return [/[nN]/, /[lL]/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /[bB]/, /\d/, /\d/];
    }

    getLetterMask(mask){
        var array_letters = {
            "B": /[bB]/,
            "N": /[nN]/,
            "L": /[lL]/,
            
            "#": /[0-9]/,
            '-': /[-]/,
            ':': /[:]/
        }

        return array_letters[mask];
    }

    
}


