<template>
    <div>
        <validation-provider v-slot="{ errors }" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <label v-if="label" class="app-input-top-label">{{label}}</label>
            <b-form-checkbox-group :options="options" :class="customClass"  v-model="cModel" :disabled="disabled" @change="changeValue">
                    <slot name="customData"></slot>
            </b-form-checkbox-group>
            <small v-if="errors.length > 0 && errors[0] != '' && cModel == null" class="ml-1 text-danger">{{ errors[0] }}</small>         
        </validation-provider>
    </div>
</template>

<script>
export default {
    name: "AppCheckboxGroup",

    props:{
        value: { type: Array },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        options: {type: Array},
        disabled: { type: Boolean, default: false },
        label: {type: String},
        customClass:{type: String, default:""},
    },

    computed:{
        cModel:{
            
            get() { return this.value },
            set(value) {this.$emit('input', value) }
        },
    },

    methods: {
        changeValue(event){
            this.$emit('change', event.target);
        }
    }

}
</script>